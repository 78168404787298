import React, { useContext } from "react";

import { useXLate } from "../hooks/useXLate";

import { ObjectContext } from "../contexts/object";

const ObjectHeader = ({children, deviceName}) => {
  const { object } = useContext(ObjectContext);

  const xlate = useXLate();

  return (
    <div className="absolute page-heading-position left-0 bg-white w-full px-4 sm:px-8 py-4 md:px-16 py-4 md:py-8 shadow">
      <div className="max-w-screen-md">
        <h1 className="whitespace-no-wrap mb-2 md:mb-4 text-primary text-base xs:text-lg sm:text-2xl lg:text-3xl xl:text-4xl">
          {children}
        </h1>
        {object &&
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="mb-2 md:mb-0 text-xs xs:text-sm sm:text-lg">
              <span className="whitespace-no-wrap">
                {object.name}, {xlate("cityShorten")} {object.city}, {object.address}
              </span>
              &nbsp;
              <span
                className={`whitespace-no-wrap mr-4 sm:mr-0 text-xs xs:text-sm sm:text-lg${
                  deviceName ? "" : " hidden"
                }`}>{deviceName}</span>
            </div>
            <span className="text-primary text-xs lg:text-sm xl:text-base">
              {xlate("lastUpdate")} {object.lastUpdateTime}
            </span>
          </div>
        }
      </div>
    </div>
  );
};

export default ObjectHeader;
