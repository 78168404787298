import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";

import useFetch from "../hooks/useFetch";
import { CurrentUserContext } from "../contexts/currentUser";
import useSessionStorage from "../hooks/useSessionStorage";
import refreshToken from "../utils/refreshToken";

const CurrentUserChecker = ({ children }) => {
  const [, dispatch] = useContext(CurrentUserContext);
  const [{ response, error }, doFetch] = useFetch("/user/profile");
  const [redirect, setRedirect] = useState(false);
  const [token] = useSessionStorage("token");

  useEffect(() => {
    if (!token) {
      dispatch({ type: "SET_UNAUTHORIZED" });
      return setRedirect(true);
    }

    doFetch();
    dispatch({ type: "LOADING" });
  }, [token, dispatch, doFetch]);

  useEffect(() => {
    if (!response) {
      return;
    }
    dispatch({ type: "SET_AUTHORIZED", payload: response });
    refreshToken(token);
  }, [response, dispatch, token]);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (error === 401 || error === 400) {
      setRedirect(true);
    }
  }, [error]);

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return children;
};

export default CurrentUserChecker;
