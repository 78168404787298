import axios from "axios";

export default (url, options = {}) => {
    const token = sessionStorage.getItem("token");

    const requestOptions = {
      ...options,
      ...{
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      },
    };

    return axios(process.env.REACT_APP_BASE_URL + url, requestOptions);
}
