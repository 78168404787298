import React, { createContext, useState, useCallback, useEffect, useContext } from "react";
import request from "../utils/request";

import { useErrorHandler } from "../hooks/useErrorHandler";

import { ObjectContext } from "../contexts/object";
import { CurrentUserContext } from "../contexts/currentUser";

export const DevicesContext = createContext();

const sortDevices = devices => devices.sort((a, b) => a.number - b.number);

export const DevicesProvider = ({ children }) => {
  const errorHandler = useErrorHandler();
  const [bays, setBays] = useState([]);
  const [vaccs, setVaccs] = useState([]);
  const [texes, setTexes] = useState([]);
  const [fmdses, setFmdses] = useState([]);

  const [{ currentUser }] = useContext(CurrentUserContext);
  const { id: objectId, groups } = useContext(ObjectContext);

  const urlBase = groups.includes("financialStatistics") ? "/user" : "/public";
  const objectURL = `${urlBase}/objects/${objectId}`;

  const [fetchParams, setFetchParams] = useState({});

  useEffect(() => {
    const storagePeriod = sessionStorage.getItem("object_period");
    if (storagePeriod)
      setFetchParams(prevState => {
        const period = storagePeriod === "1" ? "collection" : "month";

        if (prevState.period === period) return prevState;
        return { period };
      });
    else if (currentUser && currentUser.preferences)
      setFetchParams(prevState => {
        const period = currentUser.preferences.ui.object_page.period === "collection" ? "collection" : "month";

        if (prevState.period === period) return prevState;
        return { period };
      });
    else
      setFetchParams(prevState => {
        const period = "month";
        if (prevState.period === period) return prevState;
        return { period };
      });
  }, [currentUser, setFetchParams]);

  const fetchDevices = useCallback(fetchParams => {
    if (fetchParams.period)
    {
      const handleError = error => { errorHandler(error.response ? error.response.status : null); };
      request(`${objectURL}/bays`, { params: fetchParams })
        .then(resp => { setBays(sortDevices(resp.data)); })
        .catch(handleError);
      request(`${objectURL}/vaccs`, { params: fetchParams })
        .then(resp => { setVaccs(sortDevices(resp.data)); })
        .catch(handleError);
      request(`${objectURL}/texes`, { params: fetchParams })
        .then(resp => { setTexes(sortDevices(resp.data)); })
        .catch(handleError);
      request(`${objectURL}/fmdses`, { params: fetchParams })
        .then(resp => { setFmdses(sortDevices(resp.data)); })
        .catch(handleError);
    }
  }, [setBays, setVaccs, setTexes, setFmdses, objectURL, errorHandler]);

  useEffect(() => {
    fetchDevices(fetchParams);
  }, [fetchDevices, fetchParams]);

  return (
    <DevicesContext.Provider value={{ bays, vaccs, texes, fmdses, fetchDevices }}>
      {children}
    </DevicesContext.Provider>
  );
};
