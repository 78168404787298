import { useState, useEffect, useCallback } from "react";

import request from "../utils/request";

import { useMessage } from "../contexts/messages";

import getText from "../utils/text";

const text = getText();

export default url => {
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(undefined);
  const [fullResponse, setFullResponse] = useState(null);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState({});
  const { addMessage } = useMessage();

  const doFetch = useCallback((options = {}) => {
    setOptions(options);
    setIsLoading(true);
    setError(null);
  }, []);

  useEffect(() => {
    let skipGetResponseAfterDestroy = false;
    if (!isLoading) {
      return;
    }

    request(url, options)
      .then(response => {
        if (!skipGetResponseAfterDestroy) {
          // console.log("response", response);
          setFullResponse(response);
          setResponse(response.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (!skipGetResponseAfterDestroy) {
          // console.log("error", error.response.status);
          setIsLoading(false);
          if (error.response) setError(error.response.status);
          else addMessage(text.anErrorOccuredPleaseTryAgainLater["en-en"]);
        }
      });
    return () => {
      skipGetResponseAfterDestroy = true;
    };
  }, [isLoading, options, url, addMessage]);

  return [{ isLoading, response, fullResponse, error }, doFetch];
};
