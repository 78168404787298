import React, { useContext } from "react";
import { Route } from "react-router-dom";

import { ObjectContext } from "../contexts/object";
import ObjectHeader from "./ObjectHeader";

const PermRoute = ({children, group, ...rest}) => {
  const { groups } = useContext(ObjectContext);
  return (<Route {...rest} render={() => groups.includes(group) ? (children) : (<ObjectHeader/>) }/>);
};

export default PermRoute;
