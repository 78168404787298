import React, { useEffect, useState, useContext } from "react";

import { Redirect } from "react-router-dom";
import { ObjectsContext } from "../contexts/objects";
const ObjectRedirect = () => {
  const { objects } = useContext(ObjectsContext);
  const [objectId, setObjectId] = useState(null);

  useEffect(() => {
    if (!objects.length) {
      return;
    }
    setObjectId(objects[0].id);
  }, [objects]);

  if (objectId) {
    return <Redirect to={`/stat/object/${objectId}`} />;
  }

  return <div></div>;
};

export default ObjectRedirect;
