import React from "react";
import { createPortal } from "react-dom";

import Message from "../components/Message";

const MessageContainer = ({ messages }) => {
  return createPortal(
    <div className="absolute bottom-0 left-0">
      {messages.map(({ id, content }) => (
        <Message key={id} id={id}>
          {content}
        </Message>
      ))}
    </div>,
    document.body
  );
};

export default MessageContainer;
