import React from "react";

import Logo from "../images/icw_logo.svg";

const Loader = ({ type, height }) => {
  const styles = {};
  if (height) styles.height = height;

  return (
    <div
      style={{ ...styles }}
      className={`h-screen flex justify-center items-center${
        type === "table"
          ? " z-10 relative w-full bg-white"
          : type === "main-window"
          ? " relative w-auto flex-grow bg-background-color z-50"
          : " fixed z-50 w-screen bg-background-color"
      }`}>
      {type === "table" ? (
        <div className="bg-background-color flex justify-center items-center w-full h-full z-0 opacity-50">
          <img src={Logo} className="h-16" alt="logo" />
        </div>
      ) : (
        <img src={Logo} className="h-16" alt="logo" />
      )}
    </div>
  );
};

export default Loader;
