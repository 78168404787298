import React, { useState, useContext, useCallback, createContext } from "react";
import MessageContainer from "../components/MessageContainer";

const MessageContext = createContext(null);

let id = 1;

export const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addMessage = useCallback(
    content => {
      setMessages(messages => [
        ...messages,
        {
          id: id++,
          content,
        },
      ]);
    },
    [setMessages]
  );

  const removeMessage = useCallback(
    id => {
      setMessages(messages => messages.filter(t => t.id !== id));
    },
    [setMessages]
  );

  return (
    <MessageContext.Provider value={{ addMessage, removeMessage }}>
      {children}
      <MessageContainer messages={messages} />
    </MessageContext.Provider>
  );
};

const useMessage = () => {
  const messageHelpers = useContext(MessageContext);

  return messageHelpers;
};

export { MessageContext, useMessage };
