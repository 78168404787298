import { useEffect, useContext } from "react";
import useFetch from "../hooks/useFetch";
import { format, parseISO } from "date-fns";

import { useCurrentUserLang } from "../hooks/useXLate";
import { useErrorHandler } from "../hooks/useErrorHandler";

import { ObjectsContext } from "../contexts/objects";

import { sortObjectsByCity } from "../utils/objects";

const formatLastUpdate = o => ({ ...o, lastUpdateTime: format(parseISO(o.lastUpdateTime), "dd.MM.yyyy HH:mm:ss") });

const ObjectsChecker = ({ children }) => {
  const lang = useCurrentUserLang();
  const errorHandler = useErrorHandler();
  const { addObjects } = useContext(ObjectsContext);
  const [{ response, error }, doFetch] = useFetch("/user/objects");

  useEffect(() => {
    doFetch();
  }, [doFetch]);

  useEffect(() => {
    if (error) errorHandler(error);
  }, [error, errorHandler]);

  useEffect(() => {
    if (!response) {
      return;
    }
    addObjects(sortObjectsByCity(response.map(formatLastUpdate), lang));
  }, [response, addObjects, lang]);

  return children;
};

export default ObjectsChecker;
