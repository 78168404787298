import React, { useContext } from "react";

import { Redirect, useParams } from "react-router-dom";
import { DevicesContext } from "../contexts/devices";

const ConfigRedirect = () => {
  const { oid } = useParams();
  const { bays } = useContext(DevicesContext);

  if (bays[0]) {
    return <Redirect to={`/stat/object/${oid}/bay/${bays[0].id}/config`} />;
  }

  return <div></div>;
};

export default ConfigRedirect;
