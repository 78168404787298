export const sortObjectsByCity = (objects, lang) => {
  const objectsClone = [...objects];

  return objectsClone.sort((a, b) => {
    const locale = lang.slice(0, 2);

    const cityCompare = a.city.localeCompare(b.city, locale);

    if (cityCompare === 0) return a.address.localeCompare(b.address, locale);

    return cityCompare;
  });
};
