import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./styles/tailwind.css";
import Routes from "./routes";
import { CurrentUserProvider } from "./contexts/currentUser";
import { ObjectsProvider } from "./contexts/objects";
import { MessageProvider } from "./contexts/messages";

const App = () => {
  return (
    <CurrentUserProvider>
      <ObjectsProvider>
        <MessageProvider>
          <Router>
            <Routes />
          </Router>
        </MessageProvider>
      </ObjectsProvider>
    </CurrentUserProvider>
  );
};
ReactDOM.render(<App />, document.getElementById("root"));
