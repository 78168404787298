export default () => ({
  menu: {
    "ru-ru": "Меню",
    "pl-pl": "Menu",
    "ua-ua": "Меню",
    "en-en": "Menu",
    "hu-hu": "Menü",
  },

  date: {
    "ru-ru": "Дата",
    "pl-pl": "Data",
    "ua-ua": "Дата",
    "en-en": "Date",
    "hu-hu": "Dátum",
  },

  timeZone: {
    "ru-ru": "Часовой пояс:",
    "pl-pl": "Strefa czasowa:",
    "ua-ua": "Часовий пояс:",
    "en-en": "Timezone:",
    "hu-hu": "Időzóna:",
  },

  lastUpdate: {
    "ru-ru": "Последнее обновление:",
    "pl-pl": "Ostatnia aktualizacja:",
    "ua-ua": "Останне оновлення:",
    "en-en": "Last update:",
    "hu-hu": "Utolsó frissítés:",
  },

  anErrorOccuredPleaseTryAgainLater: {
    "ru-ru": "Произошла ошибка, попробуйте еще раз позже",
    "pl-pl": "Wystąpił błąd. Spróbuj ponownie później",
    "ua-ua": "Сталася помилка, спробуйте ще раз пізніше",
    "en-en": "An error occured, please try again later",
    "hu-hu": "Hiba történt, kérjük, próbálja újra később!",
  },

  notCorrectLoginOrPassword: {
    "ru-ru": "Неверный логин или пароль",
    "pl-pl": "Nieprawidłowy login lub hasło",
    "ua-ua": "Невирній логін або пароль",
    "en-en": "Not correct login or password",
    "hu-hu": "Hibás felhasználónév vagy jelszó",
  },

  settingsUnavailable: {
    "ru-ru": "Настройки недоступны",
    "pl-pl": "Ustawienia niedostępne",
    "ua-ua": "Налаштування недоступні",
    "en-en": "Settings unavailable",
    "hu-hu": "A beállítások nem érhetők el",
  },

  signIn: {
    "ru-ru": "Вход",
    "pl-pl": "wejście",
    "ua-ua": "Вхід",
    "en-en": "Sign-in",
    "hu-hu": "Bejelentkezés",
  },

  signOut: {
    "ru-ru": "Выход",
    "pl-pl": "wyjście",
    "ua-ua": "Вихід",
    "en-en": "Sign-out",
    "hu-hu": "Kilépés",
  },

  login: {
    "ru-ru": "Логин",
    "pl-pl": "Login",
    "ua-ua": "Логін",
    "en-en": "Login",
    "hu-hu": "Felhasználónév",
  },

  password: {
    "ru-ru": "Пароль",
    "pl-pl": "Hasło",
    "ua-ua": "Пароль",
    "en-en": "Password",
    "hu-hu": "Jelszó",
  },

  forgotPassword: {
    "ru-ru": "Забыл пароль",
    "pl-pl": "Zapomniałeś hasła",
    "ua-ua": "Забув пароль",
    "en-en": "Forgot password",
    "hu-hu": "Elfelejtett jelszó",
  },

  invalidKey: {
    "ru-ru": "Неверный ключ",
    "pl-pl": "Niewłaściwy klucz",
    "ua-ua": "Невірний ключ",
    "en-en": "Invalid key",
    "hu-hu": "Érvénytelen munkamenet",
  },

  keyExpiredPleaseRetryRequest: {
    "ru-ru": "Ключ закончился повторите запрос",
    "pl-pl": "Klucz wygasł, spróbuj ponownie",
    "ua-ua": "Ключ закінчився, повторити запит",
    "en-en": "Key expired, please retry request",
    "hu-hu": "A munkamenet lejárt, ismételje meg a kérést",
  },

  passwordRecovery: {
    "ru-ru": "Восстановление пароля",
    "pl-pl": "Odzyskiwanie hasła",
    "ua-ua": "Відновлення паролю",
    "en-en": "Password recovery",
    "hu-hu": "Jelszóvisszaállítás",
  },

  restorePassword: {
    "ru-ru": "Восстановить пароль",
    "pl-pl": "Przywróć hasło",
    "ua-ua": "Відновити пароль",
    "en-en": "Restore password",
    "hu-hu": "Jelszó visszaállítása",
  },

  fillInTheEmail: {
    "ru-ru": "Заполните email",
    "pl-pl": "Wpisz swój e-mail",
    "ua-ua": "Заповніть email",
    "en-en": "Fill in your email",
    "hu-hu": "Adja meg e-mail címét",
  },

  youHaveBeenSentALinkToResetYourPasswordByEmail: {
    "ru-ru": "Вам на почту отправили ссылку для восстановления пароля",
    "pl-pl": "Link do zresetowania hasła został wysłany do Ciebie e-mailem",
    "ua-ua": "Вам на пошту відправили посилання для відновлення пароля",
    "en-en": "You have been sent a link to reset your password by email",
    "hu-hu": "E mailben elküldtük a jelszó visszaállítására szolgáló linket",
  },

  noSuchEmail: {
    "ru-ru": "Нет такого email",
    "pl-pl": "Brak takiego e-maila",
    "ua-ua": "Немає такого email",
    "en-en": "No such email",
    "hu-hu": "Nincs ilyen e-mail",
  },

  checkEmailOrTryALittleLater: {
    "ru-ru": "Проверьте email или попробуйте немного позже",
    "pl-pl": "Sprawdź pocztę lub spróbuj  później",
    "ua-ua": "Перевірте email або спробуйте трохи пізніше",
    "en-en": "Check email or try a little later",
    "hu-hu": "Ellenőrizze az e-mail címét, vagy próbálkozzon később",
  },

  enterYourEmailALinkToChangeYourPasswordWillBeSentToIt: {
    "ru-ru": "Укажите ваш E-mail. На него будет выслана ссылка для смены пароля",
    "pl-pl": "Wprowadź swój email. Zostanie do niego wysłany link do zmiany hasła.",
    "ua-ua": "Вкажіть ваш E-mail. На нього буде вислано посилання для зміни пароля",
    "en-en": "Enter your E-mail. A link to change your password will be sent to it.",
    "hu-hu": "Adja meg email címét. A rendszer elküldi a jelszó megváltoztatására szolgáló linket.",
  },

  service: {
    "ru-ru": "Сервис",
    "pl-pl": "Serwis",
    "ua-ua": "Сервіс",
    "en-en": "Service",
    "hu-hu": "Karbantartás",
  },

  missingOrInvalidRequestParameters: {
    "ru-ru": "Отсутствуют или неверные параметры запроса",
    "pl-pl": "Brakujące lub nieprawidłowe parametry żądania",
    "ua-ua": "Відсутні або неправильні парметри запиту",
    "en-en": "Missing or invalid request parameters",
    "hu-hu": "Hiányzó vagy érvénytelen paraméterek",
  },

  authorizationRequired: {
    "ru-ru": "Нужна авторизация",
    "pl-pl": "Wymagana Autoryzacja",
    "ua-ua": "Потрібна авторизація",
    "en-en": "Authorization required",
    "hu-hu": "Bejelentkezés szükséges",
  },

  unknownObjectID: {
    "ru-ru": "Неизвестный ID обьекта",
    "pl-pl": "Nieznany identyfikator obiektu",
    "ua-ua": "Невідомий ID об'єкта",
    "en-en": "Unknown object ID",
    "hu-hu": "Ismeretlen létesítményazonosító",
  },

  passwordsAreEmpty: {
    "ru-ru": "Пароли пустые",
    "pl-pl": "Hasła są puste",
    "ua-ua": "Паролі порожні",
    "en-en": "passwords are empty",
    "hu-hu": "A jelszavak üresek",
  },

  passwordsDontMatch: {
    "ru-ru": "Пароли не совпадают",
    "pl-pl": "Hasło różni się",
    "ua-ua": "Паролі не співпадають",
    "en-en": "Passwords don't match",
    "hu-hu": "A jelszavak nem egyeznek",
  },

  passwordChanged: {
    "ru-ru": "Пароль изменен",
    "pl-pl": "Hasło zostało zmienione",
    "ua-ua": "Пароль змінений",
    "en-en": "Password changed",
    "hu-hu": "A jelszó megváltozott",
  },

  invalidOldPassword: {
    "ru-ru": "Неверный старый пароль",
    "pl-pl": "Nieprawidłowe stare hasło",
    "ua-ua": "Невірний старий пароль",
    "en-en": "Invalid old password",
    "hu-hu": "Hibás régi jelszó",
  },

  passwordChange: {
    "ru-ru": "Смена пароля",
    "pl-pl": "Zmiana hasła",
    "ua-ua": "Зміна пароля",
    "en-en": "Password change",
    "hu-hu": "Jelszó módosítása",
  },

  oldPassword: {
    "ru-ru": "Старый пароль",
    "pl-pl": "Stare hasło",
    "ua-ua": "Старий пароль",
    "en-en": "Old Password",
    "hu-hu": "Régi jelszó",
  },

  newPassword: {
    "ru-ru": "Новый пароль",
    "pl-pl": "Nowe hasło",
    "ua-ua": "Новий пароль",
    "en-en": "New Password",
    "hu-hu": "Új jelszó",
  },

  passwordConfirmation: {
    "ru-ru": "Подтверждение пароля",
    "pl-pl": "Potwierdzenie hasła",
    "ua-ua": "Підтвердження пароля",
    "en-en": "Password confirmation",
    "hu-hu": "Jelszó megerősítése",
  },

  save: {
    "ru-ru": "Сохранить",
    "pl-pl": "Zapisać",
    "ua-ua": "Зберегти",
    "en-en": "Save",
    "hu-hu": "Mentés",
  },

  fillInTheFields: {
    "ru-ru": "Заполните поля",
    "pl-pl": "Wypełnij pola",
    "ua-ua": "Заповніть поля",
    "en-en": "Fill in the fields",
    "hu-hu": "Töltse ki a mezőket",
  },

  dataUpdated: {
    "ru-ru": "Данные обновлены",
    "pl-pl": "Zaktualizowano dane",
    "ua-ua": "Дані оновлені",
    "en-en": "Data updated",
    "hu-hu": "Az adatok frissítve",
  },

  personalInformation: {
    "ru-ru": "Персональные данные",
    "pl-pl": "Personalne dane",
    "ua-ua": "Персональні дані",
    "en-en": "Personal Information",
    "hu-hu": "Felhasználói profil",
  },

  fullName: {
    "ru-ru": "Имя",
    "pl-pl": "Imię",
    "ua-ua": "Iм'я",
    "en-en": "Name",
    "hu-hu": "Név",
  },

  language: {
    "ru-ru": "Язык",
    "pl-pl": "Język",
    "ua-ua": "Мова",
    "en-en": "Language",
    "hu-hu": "Nyelv",
  },

  periodOnTheObjectPage: {
    "ru-ru": "Период на странице обьекта",
    "pl-pl": "Czas na stronie obiektu",
    "ua-ua": "Період на сторінці об'єкта",
    "en-en": "Period on the object page",
    "hu-hu": "Alapértelmezett megjelenítés",
  },

  show: {
    "ru-ru": "Показать",
    "pl-pl": "Pokazać",
    "ua-ua": "Показати",
    "en-en": "Show",
    "hu-hu": "Megjelenít",
  },

  all: {
    "ru-ru": "Все",
    "pl-pl": "Wszystko",
    "ua-ua": "Всі",
    "en-en": "All",
    "hu-hu": "Összes",
  },

  post: {
    "ru-ru": "Пост",
    "pl-pl": "Stanowisko",
    "ua-ua": "Пост",
    "en-en": "Post",
    "hu-hu": "Állás",
  },

  posts: {
    "ru-ru": "Посты",
    "pl-pl": "Stanowiska",
    "ua-ua": "Пости",
    "en-en": "Posts",
    "hu-hu": "Állás",
  },

  postNo: {
    "ru-ru": "Пост №",
    "pl-pl": "Stanowisko №",
    "ua-ua": "Пост №",
    "en-en": "Post No.",
    "hu-hu": "Állás száma",
  },

  ofThePost: {
    "ru-ru": "поста",
    "pl-pl": "Stanowiska",
    "ua-ua": "Поста",
    "en-en": "of the post",
    "hu-hu": "állás",
  },

  ofThePosts: {
    "ru-ru": "постов",
    "pl-pl": "Stanowisk",
    "ua-ua": "Постів",
    "en-en": "of the posts",
    "hu-hu": "állás",
  },

  vacc: {
    "ru-ru": "Пылесос",
    "pl-pl": "Odkurzacz",
    "ua-ua": "Пилосос",
    "en-en": "Vacuum cleaner",
    "hu-hu": "Porszívó",
  },

  vaccs: {
    "ru-ru": "Пылесосы",
    "pl-pl": "Odkurzacze",
    "ua-ua": "Пилососи",
    "en-en": "Vacuum cleaners",
    "hu-hu": "Porszívó",
  },

  vaccNo: {
    "ru-ru": "Пылесос №",
    "pl-pl": "Odkurzacz №",
    "ua-ua": "Пилосос №",
    "en-en": "Vacuum cleaner no.",
    "hu-hu": "Porszívó száma",
  },

  ofTheVacc: {
    "ru-ru": "пылесоса",
    "pl-pl": "Odkurzacza",
    "ua-ua": "Пилососа",
    "en-en": "of the vacuum cleaner",
    "hu-hu": "porszívó",
  },

  ofTheVaccs: {
    "ru-ru": "пылесосов",
    "pl-pl": "Odkurzaczy",
    "ua-ua": "Пилососів",
    "en-en": "of the vacuum cleaners",
    "hu-hu": "porszívó",
  },

  tex: {
    "ru-ru": "Разменник",
    "pl-pl": "Rozmieniarka",
    "ua-ua": "Обмінник",
    "en-en": "Exchanger",
    "hu-hu": "Váltógép",
  },

  texes: {
    "ru-ru": "Разменники",
    "pl-pl": "Rozmieniarka",
    "ua-ua": "Обмінник",
    "en-en": "Exchangers",
    "hu-hu": "Váltógép",
  },

  texNo: {
    "ru-ru": "Разменник №",
    "pl-pl": "Rozmieniarka №",
    "ua-ua": "Обмінник №",
    "en-en": "Exchanger No.",
    "hu-hu": "Váltógép száma",
  },

  ofTheTex: {
    "ru-ru": "разменника",
    "pl-pl": "Razmieniarka",
    "ua-ua": "Обмінника",
    "en-en": "of the exchanger",
    "hu-hu": "váltógép",
  },

  ofTheTexes: {
    "ru-ru": "разменников",
    "pl-pl": "Rozmieniarki",
    "ua-ua": "Обмінників",
    "en-en": "of the exchangers",
    "hu-hu": "váltógép",
  },

  fmds: {
    "ru-ru": "Фискальный сервер",
    "pl-pl": "Serwer fiskalny",
    "ua-ua": "Фіскальний сервер",
    "en-en": "Fiscal server",
    "hu-hu": "Fiskális szerver",
  },

  fmdses: {
    "ru-ru": "Фискальные серверы",
    "pl-pl": "Serwery fiskalne",
    "ua-ua": "Фіскальні сервери",
    "en-en": "Fiscal servers",
    "hu-hu": "Fiskális szerverek",
  },

  fmdsNo: {
    "ru-ru": "Фискальный сервер №",
    "pl-pl": "Serwer fiskalny №",
    "ua-ua": "Фіскальний сервер №",
    "en-en": "Fiscal server No.",
    "hu-hu": "Fiskális szerver száma",
  },

  ofTheObject: {
    "ru-ru": "обьекта",
    "pl-pl": "obiekta",
    "ua-ua": "об'єкта",
    "en-en": "of the object",
    "hu-hu": "autómosó",
  },

  yourObjects: {
    "ru-ru": "Ваши объекты",
    "pl-pl": "Twoje obiekty",
    "ua-ua": "Ваші об'єкти",
    "en-en": "Your objects",
    "hu-hu": "Autómosók",
  },

  // like г.
  cityShorten: {
    "ru-ru": "г.",
    "pl-pl": "m.",
    "ua-ua": "м.",
    "en-en": "city",
    "hu-hu": "",
  },

  financialStatistics: {
    "ru-ru": "Финансовая статистика",
    "pl-pl": "Statystyki finansowe",
    "ua-ua": "Фінансова статистика",
    "en-en": "Financial statistics",
    "hu-hu": "Pénzügyi statisztika",
  },

  usingPosts: {
    "ru-ru": "Использование постов",
    "pl-pl": "Używanie stanowisk",
    "ua-ua": "Використання постів",
    "en-en": "Using posts",
    "hu-hu": "Mosó használati statisztika",
  },

  settingUpPosts: {
    "ru-ru": "Настройка постов",
    "pl-pl": "Ustawienia stanowisk",
    "ua-ua": "Налаштування постів",
    "en-en": "Setting up posts",
    "hu-hu": "Autómosó beállítások",
  },

  usingVacuumCleaners: {
    "ru-ru": "Использование пылесосов",
    "pl-pl": "Używanie odkurzacze",
    "ua-ua": "Використання пилососів",
    "en-en": "Using vacuum cleaners",
    "hu-hu": "Porszívó használati statisztika",
  },

  usingTExes: {
    "ru-ru": "Использование разменника",
    "pl-pl": "Używanie razmieniarka",
    "ua-ua": "Використання розмінника",
    "en-en": "Using token exchanger",
    "hu-hu": "Váltógép",
  },

  telemetry: {
    "ru-ru": "Телеметрия",
    "pl-pl": "Telemetria",
    "ua-ua": "Телеметрія",
    "en-en": "Telemetry",
    "hu-hu": "Telemetria",
  },

  cash: {
    "ru-ru": "Наличные",
    "pl-pl": "Gotówka",
    "ua-ua": "Готівкові",
    "en-en": "Cash",
    "hu-hu": "Készpénz",
  },

  keys: {
    "ru-ru": "Ключи",
    "pl-pl": "Klucze",
    "ua-ua": "Ключі",
    "en-en": "Keys",
    "hu-hu": "Kulcsok",
  },

  cashless: {
    "ru-ru": "Безнал",
    "pl-pl": "Bezgotówkowo",
    "ua-ua": "Безнал",
    "en-en": "Cashless",
    "hu-hu": "Készpénzmentes",
  },

  tokens: {
    "ru-ru": "Жетоны",
    "pl-pl": "Żetony",
    "ua-ua": "Жетони",
    "en-en": "Tokens",
    "hu-hu": "Zsetonok",
  },

  application: {
    "ru-ru": "Приложение",
    "pl-pl": "Aplikacja",
    "ua-ua": "Додаток",
    "en-en": "Application",
    "hu-hu": "Mobilapp",
  },

  creditCard: {
    "ru-ru": ["Кредитные", "карты"],
    "pl-pl": ["Kartę", "platniczę"],
    "ua-ua": ["Кредитні", "карти"],
    "en-en": ["Credit", "cards"],
    "hu-hu": ["Bankkártya", ""],
  },

  internal: {
    "ru-ru": "Внутрений",
    "pl-pl": "Wewnętrzny",
    "ua-ua": "Внутрішній",
    "en-en": "Internal",
    "hu-hu": "Belső",
  },

  remainders: {
    "ru-ru": "Остатки",
    "pl-pl": "Reszta",
    "ua-ua": "Залишки",
    "en-en": "Remainders",
    "hu-hu": "Váltógépben",
  },

  summ: {
    "ru-ru": "Сумма",
    "pl-pl": "Suma",
    "ua-ua": "Сума",
    "en-en": "Amount",
    "hu-hu": "Összesen",
  },

  total: {
    "ru-ru": "Всего",
    "pl-pl": "Razem",
    "ua-ua": "Всього",
    "en-en": "Total",
    "hu-hu": "Összesen",
  },

  g1: {
    "ru-ru": "Ж1",
    "pl-pl": "G1",
    "ua-ua": "Ж1",
    "en-en": "G1",
    "hu-hu": "G1",
  },

  g2: {
    "ru-ru": "Ж2",
    "pl-pl": "G2",
    "ua-ua": "Ж2",
    "en-en": "G2",
    "hu-hu": "G2",
  },

  bonus: {
    "ru-ru": "Бонус",
    "pl-pl": "Bonus",
    "ua-ua": "Бонус",
    "en-en": "Bonus",
    "hu-hu": "Bonus",
  },

  currency: {
    "ru-ru": "грн",
    "pl-pl": "pln",
    "ua-ua": "грн",
    "en-en": "UAH",
    "hu-hu": "Ft",
  },

  peaces: {
    "ru-ru": "шт",
    "pl-pl": "szt",
    "ua-ua": "шт",
    "en-en": "pcs",
    "hu-hu": "db",
  },

  apply: {
    "ru-ru": "Применить",
    "pl-pl": "Zastosować",
    "ua-ua": "Застосувати",
    "en-en": "Apply",
    "hu-hu": "Alkalmaz",
  },

  applyToAll: {
    "ru-ru": "Применить ко всем",
    "pl-pl": "Stosuj się do wszystkich",
    "ua-ua": "Застосувати до всіх",
    "en-en": "Apply to all",
    "hu-hu": "Alkalmaz minden állásra",
  },

  rates: {
    "ru-ru": "Тарифы",
    "pl-pl": "Kurs",
    "ua-ua": "Тарифи",
    "en-en": "Tariffs",
    "hu-hu": "Díja",
  },

  dayOfTheWeek: {
    "ru-ru": "День недели",
    "pl-pl": "Dzień tygodnia",
    "ua-ua": "День тижня",
    "en-en": "Day of the week",
    "hu-hu": "Hét napja",
  },

  timeFrom: {
    "ru-ru": "Время, с",
    "pl-pl": "Godzina, od",
    "ua-ua": "Час, з",
    "en-en": "Time from",
    "hu-hu": "Ettől",
  },

  timeUntil: {
    "ru-ru": "Время, до",
    "pl-pl": "Godzina, do",
    "ua-ua": "Час, до",
    "en-en": "Time until",
    "hu-hu": "Eddig",
  },

  promo: {
    "ru-ru": "Акция, %",
    "pl-pl": "Promo, %",
    "ua-ua": "Акція, %",
    "en-en": "Promo, %",
    "hu-hu": "Akció, %",
  },

  generalSettings: {
    "ru-ru": "Общие настройки",
    "pl-pl": "Ustawienia główne",
    "ua-ua": "Загальні налаштування",
    "en-en": "General settings",
    "hu-hu": "Általános beállítások",
  },

  tokenCost: {
    "ru-ru": "Стоимость жетона:",
    "pl-pl": "Koszt żetonu:",
    "ua-ua": "Вартість жетона:",
    "en-en": "Token cost:",
    "hu-hu": "Érme értéke:",
  },

  posAmount: {
    "ru-ru": "Сумма списания POS:",
    "pl-pl": "POS płatność:",
    "ua-ua": "Сума списання POS:",
    "en-en": "POS payment:",
    "hu-hu": "POS fizetés:",
  },

  stopRate: {
    "ru-ru": "Тариф на СТОП:",
    "pl-pl": "Kurs STOP:",
    "ua-ua": "Тариф на СТОП:",
    "en-en": "STOP rate:",
    "hu-hu": "STOP díja:",
  },

  delayOnStop: {
    "ru-ru": "Задержка на СТОП:",
    "pl-pl": "Opóźnienie na STOP:",
    "ua-ua": "Затримка на СТОП:",
    "en-en": "Delay on STOP:",
    "hu-hu": "Késleltetés STOP esetén:",
  },

  sec: {
    "ru-ru": "сек",
    "pl-pl": "sek",
    "ua-ua": "сек",
    "en-en": "sec",
    "hu-hu": "mp",
  },

  stop: {
    "ru-ru": "Стоп",
    "pl-pl": "zatrzymać",
    "ua-ua": "Стоп",
    "en-en": "Stop",
    "hu-hu": "álljon meg",
  },

  programs: {
    "ru-ru": "Программы",
    "pl-pl": "Programy",
    "ua-ua": "Програми",
    "en-en": "Programs",
    "hu-hu": "Programok",
  },

  program: {
    "ru-ru": "Программа",
    "pl-pl": "Program",
    "ua-ua": "Програма",
    "en-en": "Program",
    "hu-hu": "Program",
  },

  workTimeFor: {
    "ru-ru": "Время работы на",
    "pl-pl": "Czas pracy za",
    "ua-ua": "Час роботи за",
    "en-en": "Work time for",
    "hu-hu": "Használati idő",
  },

  antiInsect: {
    "ru-ru": "Антимошка",
    "pl-pl": "Środek na owady",
    "ua-ua": "Антимошка",
    "en-en": "Anti Insect",
    "hu-hu": "Bogároldó",
  },

  foam: {
    "ru-ru": "Пена",
    "pl-pl": "Piana",
    "ua-ua": "Піна",
    "en-en": "Foam",
    "hu-hu": "Habfürdő",
  },

  cleaner: {
    "ru-ru": "Очиститель",
    "pl-pl": "Szampon",
    "ua-ua": "Очищувач",
    "en-en": "Cleaner",
    "hu-hu": "Samponos mosás",
  },

  hotWater: {
    "ru-ru": "Горячая вода",
    "pl-pl": "Gorąca woda",
    "ua-ua": "Гаряча вода",
    "en-en": "Hot water",
    "hu-hu": "Kefés mosás",
  },

  warmWater: {
    "ru-ru": "Теплая вода",
    "pl-pl": "Ciepła woda",
    "ua-ua": "Тепла вода",
    "en-en": "Warm water",
    "hu-hu": "Kefés mosás",
  },

  coldWater: {
    "ru-ru": "Холодная вода",
    "pl-pl": "Żimna woda",
    "ua-ua": "Холодна вода",
    "en-en": "Cold water",
    "hu-hu": "Öblítés",
  },

  wax: {
    "ru-ru": "Воск",
    "pl-pl": "Wosk",
    "ua-ua": "Віск",
    "en-en": "Wax",
    "hu-hu": "Ápoló viasz",
  },
  osmosWater: {
    "ru-ru": "Осмос вода",
    "pl-pl": "Osmoza",
    "ua-ua": "Осмос вода",
    "en-en": "Osmosis water",
    "hu-hu": "Föltmentes öblítés",
  },

  osmos: {
    "ru-ru": "Осмос",
    "pl-pl": "Osmoza",
    "ua-ua": "Осмос",
    "en-en": "Osmosis",
    "hu-hu": "Föltmentes öblítés",
  },

  key: {
    "ru-ru": "Ключ",
    "pl-pl": "Klucz",
    "ua-ua": "Ключ",
    "en-en": "Key",
    "hu-hu": "Kulcs",
  },

  cardBonus: {
    "ru-ru": "Бонус на ключ",
    "pl-pl": "Bonus na klucz",
    "ua-ua": "Бонус на ключ",
    "en-en": "Bonus on key",
    "hu-hu": "Kulcs bónusz",
  },

  minPayment: {
    "ru-ru": "Минимальная сумма",
    "pl-pl": "Minimalna płatność",
    "ua-ua": "Мінімальна сума",
    "en-en": "Minamal payment",
    "hu-hu": "Minimális fizetés",
  },

  paymentType: {
    "ru-ru": "Тип платежа",
    "pl-pl": "Typ płatności",
    "ua-ua": "Тип платежу",
    "en-en": "Payment type",
    "hu-hu": "Fizetési mód",
  },

  sales: {
    "ru-ru": "Продажи",
    "pl-pl": "Sales",
    "ua-ua": "Продажі",
    "en-en": "Sales",
    "hu-hu": "Sales",
  },

  drains: {
    "ru-ru": "Техническая выдача",
    "pl-pl": "Drains",
    "ua-ua": "Технічна видача",
    "en-en": "Drains",
    "hu-hu": "Drains",
  },

  fills: {
    "ru-ru": "Наполнение",
    "pl-pl": "Fills",
    "ua-ua": "Наповнення",
    "en-en": "Fills",
    "hu-hu": "Fills",
  },

  errors: {
    "ru-ru": "Ошибки",
    "pl-pl": "Errors",
    "ua-ua": "Помилки",
    "en-en": "Errors",
    "hu-hu": "Errors",
  },

  cardRefills: {
    "ru-ru": "Пополнения карт",
    "pl-pl": "Uzupełnianie kart",
    "ua-ua": "Поповнення карт",
    "en-en": "Card refills",
    "hu-hu": "Kártya utántöltések",
  },

  cardRefill: {
    "ru-ru": "Пополнение",
    "pl-pl": "Uzupełnienie karty",
    "ua-ua": "Поповнення",
    "en-en": "Card refill",
    "hu-hu": "Kártya utántöltés",
  },

  status: {
    "ru-ru": "Статус:",
    "pl-pl": "Status:",
    "ua-ua": "Статус:",
    "en-en": "Status:",
    "hu-hu": "Állapot:",
  },

  state: {
    "ru-ru": "Состояние:",
    "pl-pl": "Stan:",
    "ua-ua": "Стан:",
    "en-en": "State:",
    "hu-hu": "Állapot:",
  },

  balance: {
    "ru-ru": "Баланс:",
    "pl-pl": "Bilans:",
    "ua-ua": "Баланс:",
    "en-en": "Balance:",
    "hu-hu": "Egyenleg",
  },

  electronics: {
    "ru-ru": "Электроника:",
    "pl-pl": "Elektronika:",
    "ua-ua": "Електроніка:",
    "en-en": "Hardware:",
    "hu-hu": "Elektronika:",
  },

  activity: {
    "ru-ru": "Активность:",
    "pl-pl": "Aktywność:",
    "ua-ua": "Активність:",
    "en-en": "Activity:",
    "hu-hu": "Aktivitás:",
  },

  delay: {
    "ru-ru": "Задержка:",
    "pl-pl": "Opóźnienie:",
    "ua-ua": "Затримка:",
    "en-en": "Delay:",
    "hu-hu": "Késleltetés:",
  },

  loading: {
    "ru-ru": "Загрузка",
    "pl-pl": "Ładowanie",
    "ua-ua": "Завантаження",
    "en-en": "Loading",
    "hu-hu": "Betöltés",
  },

  detailing: {
    "ru-ru": "Детализация:",
    "pl-pl": "Szczegóły:",
    "ua-ua": "Деталізація:",
    "en-en": "Detailing:",
    "hu-hu": "Részletezés:",
  },

  month: {
    "ru-ru": "месяц",
    "pl-pl": "miesiąc",
    "ua-ua": "місяць",
    "en-en": "month",
    "hu-hu": "aktuális hónap",
  },

  day: {
    "ru-ru": "день",
    "pl-pl": "dzień",
    "ua-ua": "день",
    "en-en": "day",
    "hu-hu": "ma",
  },

  hour: {
    "ru-ru": "час",
    "pl-pl": "czas",
    "ua-ua": "година",
    "en-en": "hour",
    "hu-hu": "óra",
  },

  maximum: {
    "ru-ru": "максимальная",
    "pl-pl": "maksymalna",
    "ua-ua": "максимальна",
    "en-en": "maximum",
    "hu-hu": "maximális",
  },

  byTime: {
    "ru-ru": "По времени",
    "pl-pl": "wg czasu",
    "ua-ua": "По часу",
    "en-en": "By time",
    "hu-hu": "Idő szerint",
  },

  byNumberOfInclusions: {
    "ru-ru": "По количеству включений",
    "pl-pl": "wg liczby załączeń",
    "ua-ua": "За кількістю включень",
    "en-en": "Use count",
    "hu-hu": "Programindítások száma szerint",
  },

  period: {
    "ru-ru": "Период:",
    "pl-pl": "Okres:",
    "ua-ua": "Період:",
    "en-en": "Period:",
    "hu-hu": "Időszak:",
  },

  year: {
    "ru-ru": "год",
    "pl-pl": "rok",
    "ua-ua": "рік",
    "en-en": "year",
    "hu-hu": "idén",
  },

  arbitrary: {
    "ru-ru": "произвольный",
    "pl-pl": "arbitralny",
    "ua-ua": "довільний",
    "en-en": "arbitrary",
    "hu-hu": "tetszőleges",
  },

  payments: {
    "ru-ru": "Платежи",
    "pl-pl": "Płatności",
    "ua-ua": "Платежі",
    "en-en": "Payments",
    "hu-hu": "Bevételek",
  },

  cashCollections: {
    "ru-ru": "Инкассации",
    "pl-pl": "Inkasacji",
    "ua-ua": "Iнкасації",
    "en-en": "Cash collection",
    "hu-hu": "Pénz begyűjtése",
  },

  autoCollection: {
    "ru-ru": "Автосбор",
    "pl-pl": "Zbieranie automatyczne",
    "ua-ua": "Автозбір",
    "en-en": "Auto collection",
    "hu-hu": "Automatikus",
  },

  manual: {
    "ru-ru": "Ручная",
    "pl-pl": "Ręczne",
    "ua-ua": "Ручна",
    "en-en": "Manual",
    "hu-hu": "Kézi",
  },

  billCollector: {
    "ru-ru": "Инкассатор",
    "pl-pl": "Inkasator",
    "ua-ua": "Інкасатор",
    "en-en": "bill collector",
    "hu-hu": "Pénzbegyűjtő",
  },

  cashCollection: {
    "ru-ru": "Инкассация",
    "pl-pl": "Inkasacja",
    "ua-ua": "інкасація",
    "en-en": "Cash collection",
    "hu-hu": "Pénz begyűjtése",
  },

  thereWereNoCahsCollections: {
    "ru-ru": "В заданом периоде инкассаций не было",
    "pl-pl": "W podanym okresie nie było inkasacji",
    "ua-ua": "У заданому періоді інкасацій не було",
    "en-en": "There were no cash collections in the specified period",
    "hu-hu": "A megadott időszakban nem volt pénzbegyűjtés",
  },

  thereWereNoPayments: {
    "ru-ru": "В заданом периоде платежей не было",
    "pl-pl": "W podanym okresie nie było żadnych płatności",
    "ua-ua": "У заданому періоді платежів не було",
    "en-en": "There were no payments in the specified period",
    "hu-hu": "A megadott időszakban nem volt bevétel",
  },

  currentMonth: {
    "ru-ru": "текущий месяц",
    "pl-pl": "obecny miesiąc",
    "ua-ua": "поточний місяць",
    "en-en": "current month",
    "hu-hu": "jelenlegi hónap",
  },

  afterEncashment: {
    "ru-ru": "после инкасации",
    "pl-pl": "Po inkasacji",
    "ua-ua": "після інкасації",
    "en-en": "after encashment",
    "hu-hu": "pénzbegyűjtés után",
  },

  thereWereNoPostsUsage: {
    "ru-ru": "В заданом периоде посты использованы не были",
    "pl-pl": "W danym okresie stanowiska nie były wykorzystywane",
    "ua-ua": "У заданому періоді пости не було використано",
    "en-en": "In a given period, posts were not used",
    "hu-hu": "A megadott időszakban az állásokat nem használták",
  },

  thereWereNoVaccsUsage: {
    "ru-ru": "В заданом периоде пылесосы использованы не были",
    "pl-pl": "W danym okresie odkurzacze nie były wykorzystywane",
    "ua-ua": "У заданому періоді пилососи не було використано",
    "en-en": "Vacuum cleaners were not used in a given period",
    "hu-hu": "A megadott időszakban a porszívókat nem használták",
  },

  thereWereNoDeviceUsage: {
    "ru-ru": "В заданом периоде устройства использованы не были",
    "pl-pl": "Urządzenia nie były używane w danym okresie",
    "ua-ua": "У заданому періоді пристрої не було використано",
    "en-en": "Devices were not used in a given period",
    "hu-hu": "Az eszközöket egy adott időszakban nem használták",
  },

  thereWereNoSales: {
    "ru-ru": "В заданом периоде продаж небыло",
    "pl-pl": "W podanym okresie nie było sprzedaży",
    "ua-ua": "У заданому періоді продажів не було",
    "en-en": "There were no sales in the given period",
    "hu-hu": "Az adott időszakban nem volt értékesítés",
  },

  thereWereNoRefills: {
    "ru-ru": "В заданом периоде пополнений небыло",
    "pl-pl": "W tym okresie nie było uzupełnień",
    "ua-ua": "У заданому періоді поповнень не було",
    "en-en": "There were no refills in the given period",
    "hu-hu": "Az adott időszakban nem volt utántöltés",
  },

  financialStatisticsForTheObject: {
    "ru-ru": "Финансовая статистика по объекту",
    "pl-pl": "Statystyka finansowa obiektu",
    "ua-ua": "Фінансова статистика по об'єкту",
    "en-en": "Financial statistics for the object",
    "hu-hu": "A létesítmény pénzügyi statisztikája",
  },

  cashboxForTheObject: {
    "ru-ru": "Касса по объекту",
    "pl-pl": "Dochód obiektu",
    "ua-ua": "Каса по об'єкту",
    "en-en": "Object totals",
    "hu-hu": "Létesítmény bevétele",
  },

  usageStatistics: {
    "ru-ru": "Статистика использования",
    "pl-pl": "Statystyka użycia",
    "ua-ua": "Статистика використання",
    "en-en": "Usage statistics",
    "hu-hu": "Használati statisztika",
  },

  timers: {
    "ru-ru": "Таймеры",
    "pl-pl": "Timer",
    "ua-ua": "Таймери",
    "en-en": "Timers",
    "hu-hu": "Emlékeztető",
  },

  addNewTimer: {
    "ru-ru": "Добавить новый таймер",
    "pl-pl": "Dodać nowy Timer",
    "ua-ua": "Додати новий таймер",
    "en-en": "Add new timer",
    "hu-hu": "Új emlékeztető hozzáadása",
  },

  timerDeleted: {
    "ru-ru": "Таймер удален",
    "pl-pl": "Usunięto Timer",
    "ua-ua": "таймер видалений",
    "en-en": "Timer deleted",
    "hu-hu": "Emlékeztető törölve",
  },

  deleteTimer: {
    "ru-ru": "Удалить таймер ?",
    "pl-pl": "Usunąć timer ?",
    "ua-ua": "Видалити таймер ?",
    "en-en": "Delete timer ?",
    "hu-hu": "Törli az emlékeztetőt ?",
  },

  delete: {
    "ru-ru": "Удалить",
    "pl-pl": "Usunąć",
    "ua-ua": "Видалити",
    "en-en": "Delete",
    "hu-hu": "Törlés",
  },

  no: {
    "ru-ru": "Нет",
    "pl-pl": "Nie",
    "ua-ua": "Ні",
    "en-en": "No",
    "hu-hu": "Mégsem",
  },

  timerReset: {
    "ru-ru": "Таймер сброшен",
    "pl-pl": "Timer zresetowany",
    "ua-ua": "Таймер скинуто",
    "en-en": "Timer reset",
    "hu-hu": "Emlékeztető visszaállítása",
  },

  resetTime: {
    "ru-ru": "Время сброса",
    "pl-pl": "Czas resetu",
    "ua-ua": "Час скидання",
    "en-en": "Reset time",
    "hu-hu": "Idő visszaállítása",
  },

  note: {
    "ru-ru": "Примечание",
    "pl-pl": "Notatka",
    "ua-ua": "Примітка",
    "en-en": "Note",
    "hu-hu": "jegyzet",
  },

  toReset: {
    "ru-ru": "Сбросить",
    "pl-pl": "Resetowanie",
    "ua-ua": "Скинути",
    "en-en": "Reset",
    "hu-hu": "Visszaállítás",
  },

  close: {
    "ru-ru": "Закрыть",
    "pl-pl": "Zamknąć",
    "ua-ua": "Закрити",
    "en-en": "Close",
    "hu-hu": "Bezárás",
  },

  timerAdded: {
    "ru-ru": "Таймер добавлен",
    "pl-pl": "Timer dodano",
    "ua-ua": "Таймер доданий",
    "en-en": "Timer added",
    "hu-hu": "Emlékeztető hozzáadva",
  },

  timerChanged: {
    "ru-ru": "Таймер изменен",
    "pl-pl": "Timer zmieniono",
    "ua-ua": "Таймер змінений",
    "en-en": "Timer changed",
    "hu-hu": "Emlékeztető módosítva",
  },

  editTimer: {
    "ru-ru": "Редактировать таймер",
    "pl-pl": "Edytuj timer",
    "ua-ua": "Редагувати таймер",
    "en-en": "Edit timer",
    "hu-hu": "Emlékeztető szerkesztése",
  },

  newTimer: {
    "ru-ru": "Новый таймер",
    "pl-pl": "Nowy timer",
    "ua-ua": "Новий таймер",
    "en-en": "New timer",
    "hu-hu": "Új emlékeztető",
  },

  itemName: {
    "ru-ru": "Название",
    "pl-pl": "Nazwa",
    "ua-ua": "Назва",
    "en-en": "Name",
    "hu-hu": "Név",
  },

  type: {
    "ru-ru": "Тип",
    "pl-pl": "Typ",
    "ua-ua": "Тип",
    "en-en": "Type",
    "hu-hu": "Típus",
  },

  engineHours: {
    "ru-ru": "моточасы",
    "pl-pl": "godziny pracy silnika",
    "ua-ua": "мотогодини",
    "en-en": "engine hours",
    "hu-hu": "üzemórák",
  },

  hours: {
    "ru-ru": "часы",
    "pl-pl": "godziny",
    "ua-ua": "години",
    "en-en": "hours",
    "hu-hu": "óra",
  },

  timeOut: {
    "ru-ru": "Таймаут",
    "pl-pl": "Timeout",
    "ua-ua": "Таймаут",
    "en-en": "Time-out",
    "hu-hu": "Időtúllépés",
  },

  timerResetHistory: {
    "ru-ru": "История сбросов таймера",
    "pl-pl": "Historia resetowania timera",
    "ua-ua": "Історія скидів таймера",
    "en-en": "Timer reset history",
    "hu-hu": "Emlékeztető visszaállítási előzményei",
  },

  age: {
    "ru-ru": "Возраст",
    "pl-pl": "Wiek",
    "ua-ua": "Вік",
    "en-en": "Age",
    "hu-hu": "Életkor",
  },

  thereWereNoResetsForThisTimer: {
    "ru-ru": "Для этого таймера сбросов не было",
    "pl-pl": "Ten timer nie był zresetowany",
    "ua-ua": "Для цього таймера скидів не було",
    "en-en": "There were no resets for this timer",
    "hu-hu": "Ez az emlékeztető még nem volt visszaállítva",
  },

  reset: {
    "ru-ru": "Сброс",
    "pl-pl": "Resetowanie",
    "ua-ua": "Скидання",
    "en-en": "Reset",
    "hu-hu": "Visszaállítás",
  },

  history: {
    "ru-ru": "История",
    "pl-pl": "Historia",
    "ua-ua": "Історія",
    "en-en": "History",
    "hu-hu": "Előzmények",
  },

  edit: {
    "ru-ru": "Редактировать",
    "pl-pl": "Edytuj",
    "ua-ua": "Редагувати",
    "en-en": "Edit",
    "hu-hu": "Szerkesztés",
  },

  timersNotSet: {
    "ru-ru": "Таймеры не заданы",
    "pl-pl": "Nie ustawiono timerów",
    "ua-ua": "Таймери не задані",
    "en-en": "Timers not set",
    "hu-hu": "Nincs beállított emlékeztető",
  },

  sunday: {
    "ru-ru": "Воскресение",
    "pl-pl": "Niedziela",
    "ua-ua": "Неділя",
    "en-en": "Sunday",
    "hu-hu": "Vasárnap",
  },

  monday: {
    "ru-ru": "Понедельник",
    "pl-pl": "Poniedziałek",
    "ua-ua": "Понеділок",
    "en-en": "Monday",
    "hu-hu": "Hétfő",
  },

  tuesday: {
    "ru-ru": "Вторник",
    "pl-pl": "Wtorek",
    "ua-ua": "Вівторок",
    "en-en": "Tuesday",
    "hu-hu": "Kedd",
  },

  wednesday: {
    "ru-ru": "Среда",
    "pl-pl": "Środa",
    "ua-ua": "Середа",
    "en-en": "Wednesday",
    "hu-hu": "Szerda",
  },

  thursday: {
    "ru-ru": "Четверг",
    "pl-pl": "Czwartek",
    "ua-ua": "Четвер",
    "en-en": "Thursday",
    "hu-hu": "Csütörtök",
  },

  friday: {
    "ru-ru": "Пятница",
    "pl-pl": "Piątek",
    "ua-ua": "П'ятниця",
    "en-en": "Friday",
    "hu-hu": "Péntek",
  },

  saturday: {
    "ru-ru": "Суббота",
    "pl-pl": "Sobota",
    "ua-ua": "Субота",
    "en-en": "Saturday",
    "hu-hu": "Szombat",
  },

  pendingApplication: {
    "ru-ru": "Ожидают применения",
    "pl-pl": "Oczekują zastosowania",
    "ua-ua": "Очікують застосування",
    "en-en": "Pending application",
    "hu-hu": "Válaszra várva",
  },

  applied: {
    "ru-ru": "Применены",
    "pl-pl": "Zastosowano",
    "ua-ua": "Застосовані",
    "en-en": "Applied",
    "hu-hu": "Változások végrehajtva",
  },

  error: {
    "ru-ru": "Ошибка",
    "pl-pl": "Błąd",
    "ua-ua": "Помилка",
    "en-en": "Error",
    "hu-hu": "Hiba",
  },

  air: {
    "ru-ru": "Воздух",
    "pl-pl": "Powietrze",
    "ua-ua": "Повітря",
    "en-en": "Air",
    "hu-hu": "Levegő",
  },

  idle: {
    "ru-ru": "Ожидание",
    "pl-pl": "bezczynny",
    "ua-ua": "Простій",
    "en-en": "Idle",
    "hu-hu": "Tétlen",
  },

  unknown: {
    "ru-ru": "Неизвестно",
    "pl-pl": "nieznany",
    "ua-ua": "Невідомо",
    "en-en": "Unknown",
    "hu-hu": "ismeretlen",
  },

  changeOfOil: {
    "ru-ru": "Замена масла",
    "pl-pl": "Wymiana oleju",
    "ua-ua": "Заміна масла",
    "en-en": "Change of oil",
    "hu-hu": "Olajcsere",
  },

  cuffsReplacement: {
    "ru-ru": "Замена манжет",
    "pl-pl": "Wymiana manżet",
    "ua-ua": "Заміна манжет",
    "en-en": "Cuffs replacement",
    "hu-hu": "Olajlehúzó gyűrűk cseréje",
  },

  valveReplacement: {
    "ru-ru": "Замена клапанов",
    "pl-pl": "Wymiana zaworów",
    "ua-ua": "Заміна клапанів",
    "en-en": "Valve replacement",
    "hu-hu": "Szelepcsere",
  },

  enterTheNameOfTheTimer: {
    "ru-ru": "Введите название таймера",
    "pl-pl": "Wpisz nazwę timera",
    "ua-ua": "Введіть назву таймера",
    "en-en": "Enter the name of the timer",
    "hu-hu": "Adja meg az emlékeztető nevét",
  },

  enterTimeout: {
    "ru-ru": "Введите таймаут",
    "pl-pl": "Wprowadź timeout",
    "ua-ua": "Введіть таймаут",
    "en-en": "Enter timeout",
    "hu-hu": "Adja meg az időtúllépést",
  },

  timeoutMustBeMoreZero: {
    "ru-ru": "Таймаут должен быть больше нуля",
    "pl-pl": "Timeout musi być wyżej od zera",
    "ua-ua": "Таймаут повинен бути більше нуля",
    "en-en": "Timeout must be more zero",
    "hu-hu": "Az időtúllépésnek nagyobbnak kell lennie, mint a nulla",
  },

  duration: {
    "ru-ru": "Длительность",
    "pl-pl": "Trwanie",
    "ua-ua": "Тривалість",
    "en-en": "Duration",
    "hu-hu": "Időtartama"
  },

  //////////////////////////////////////////////////////////////////

  work: {
    "ru-ru": "Работа",
    "pl-pl": "Praca",
    "ua-ua": "Робота",
    "en-en": "Work",
    "hu-hu": "Munka",
  },

  cwtErrors: {
    "NO_TOK1": {
      "ru-ru": "Нет жетонов №1",
      "pl-pl": "Brak żetonów nr 1",
      "ua-ua": "Немає жетонів №1",
      "en-en": "No token #1",
      "hu-hu": "Nincsenek zsetonok, №1"
    },
    "NO_TOK2": {
      "ru-ru": "Нет жетонов №2",
      "pl-pl": "Brak żetonów nr 2",
      "ua-ua": "Немає жетонів №2",
      "en-en": "No token #2",
      "hu-hu": "Nincsenek zsetonok, №2"
    },
    "MONEY_FULL": {
      "ru-ru": "Купюрник переполнен",
      "pl-pl": "Begyűjtés folyamatban",
      "ua-ua": "Купюрник переповнений",
      "en-en": "Banknote overflow",
      "hu-hu": "Bankjegy túlcsordulás"
    },
    "BANKNOTE_LIMIT": {
      "ru-ru": "Купюрник переполнен",
      "pl-pl": "Przepełnienie banknotów",
      "ua-ua": "Купюрник переповнений",
      "en-en": "Banknote overflow",
      "hu-hu": "Bankjegy túlcsordulás"
    },
    "HOPPER_FAILURE": {
      "ru-ru": "Авария хоппера",
      "pl-pl": "Awaria leja",
      "ua-ua": "Аварія хоппера",
      "en-en": "Hopper failure",
      "hu-hu": "Garat meghibásodás"
    },
    "COLLECTION": {
      "ru-ru": "Происходит инкассация",
      "pl-pl": "Kolekcja trwa",
      "ua-ua": "Триває інкасація",
      "en-en": "Collection in process",
      "hu-hu": "Begyűjtés folyamatban"
    },
    "NO_TOK1_ALL": {
      "ru-ru": "Выдача жетонов №1",
      "pl-pl": "Emisja żetonów nr 1",
      "ua-ua": "Видача жетонів №1",
      "en-en": "Draining token #1",
      "hu-hu": "№1 tokenek kiadása"
    },
    "NO_TOK2_ALL": {
      "ru-ru": "Выдача жетонов №2",
      "pl-pl": "Emisja żetonów nr 2",
      "ua-ua": "Видача жетонів №2",
      "en-en": "Draining token #2",
      "hu-hu": "№2 tokenek kiadása"
    }
  },

  texStatus: {
    "ready" : {
      "ru-ru": "Готов",
      "pl-pl": "Gotowy",
      "ua-ua": "Готовий",
      "en-en": "Ready",
      "hu-hu": "Kész"
    },
    "configuration" : {
      "ru-ru": "Настройка",
      "pl-pl": "Konfiguracja",
      "ua-ua": "Налаштування",
      "en-en": "Configuration",
      "hu-hu": "Konfigurációt"
    },
    "collection": {
      "ru-ru": "Происходит инкассация",
      "pl-pl": "Kolekcja trwa",
      "ua-ua": "Триває інкасація",
      "en-en": "Collection in process",
      "hu-hu": "Begyűjtés folyamatban"
    },
    "no tok1": {
      "ru-ru": "Нет жетонов №1",
      "pl-pl": "Brak żetonów nr 1",
      "ua-ua": "Немає жетонів №1",
      "en-en": "No token #1",
      "hu-hu": "Nincsenek zsetonok, №1"
    },
    "no tok2": {
      "ru-ru": "Нет жетонов №2",
      "pl-pl": "Brak żetonów nr 2",
      "ua-ua": "Немає жетонів №2",
      "en-en": "No token #2",
      "hu-hu": "Nincsenek zsetonok, №2"
    },
    "banknote limit": {
      "ru-ru": "Купюрник переполнен",
      "pl-pl": "Przepełnienie banknotów",
      "ua-ua": "Купюрник переповнений",
      "en-en": "Banknote overflow",
      "hu-hu": "Bankjegy túlcsordulás"
    },
    "hopper failure": {
      "ru-ru": "Авария хоппера",
      "pl-pl": "Awaria leja",
      "ua-ua": "Аварія хоппера",
      "en-en": "Hopper failure",
      "hu-hu": "Garat meghibásodás"
    },
    "vending" : {
      "ru-ru": "Размен",
      "pl-pl": "Rozwiń",
      "ua-ua": "Розмін",
      "en-en": "Exchange",
      "hu-hu": "Vált"
    },
    "drain tok1": {
      "ru-ru": "Выдача жетонов №1",
      "pl-pl": "Emisja żetonów nr 1",
      "ua-ua": "Видача жетонів №1",
      "en-en": "Draining token #1",
      "hu-hu": "№1 tokenek kiadása"
    },
    "drain tok2": {
      "ru-ru": "Выдача жетонов №2",
      "pl-pl": "Emisja żetonów nr 2",
      "ua-ua": "Видача жетонів №2",
      "en-en": "Draining token #2",
      "hu-hu": "№2 tokenek kiadása"
    }
  },

  uaprro: {
    shift: {
      "ru-ru": "Смена:",
      "pl-pl": "Zmiana:",
      "ua-ua": "Зміна:",
      "en-en": "Shift:",
      "hu-hu": "Változás:"
    },
    shiftState: {
      opened: {
        "ru-ru": "Открыта",
        "pl-pl": "Otwierany",
        "ua-ua": "Відкрита",
        "en-en": "Opened",
        "hu-hu": "Nyitott"
      },
      closed: {
        "ru-ru": "Закрыта",
        "pl-pl": "Zamknięte",
        "ua-ua": "Закрита",
        "en-en": "Closed",
        "hu-hu": "Zárva"
      },
    },

    mode: {
      "ru-ru": "Режим:",
      "pl-pl": "Tryb:",
      "ua-ua": "Режим:",
      "en-en": "Mode:",
      "hu-hu": "Mód:"
    },
    modeName: {
      online: {
        "ru-ru": "Онлайн",
        "pl-pl": "Online",
        "ua-ua": "Онлайн",
        "en-en": "Online",
        "hu-hu": "Online"
      },
      offline: {
        "ru-ru": "Оффлайн",
        "pl-pl": "Nieaktywny",
        "ua-ua": "Офлайн",
        "en-en": "Offline",
        "hu-hu": "Offline"
      },
    }
  },

  novitus: {
    state: {
      ok: {
        "ru-ru": "Ok",
        "pl-pl": "Ok",
        "ua-ua": "Ok",
        "en-en": "Ok",
        "hu-hu": "Ok"
      },
      notConnected: {
        "ru-ru": "Не подключен",
        "pl-pl": "Nie połączony",
        "ua-ua": "Не підключений",
        "en-en": "Not connected",
        "hu-hu": "Nem kapcsolódik"
      },
      error: {
        "ru-ru": "Ошибка",
        "pl-pl": "Błąd",
        "ua-ua": "Помилка",
        "en-en": "Error",
        "hu-hu": "Hiba",
      }
    }
  },

  deviceNumber: {
    "ru-ru": "№",
    "pl-pl": "Nr",
    "ua-ua": "№",
    "en-en": "#",
    "hu-hu": "№",
  },

  userMgmt: {
    usersAndPermissions: {
      "ru-ru": "Пользователи и права доступа",
      "pl-pl": "Użytkownicy i uprawnienia",
      "ua-ua": "Користувачі та права доступу",
      "en-en": "Users and permissions",
      "hu-hu": "Felhasználók és engedélyek",
    },

    thereAreNoUsers: {
      "ru-ru": "Нет пользователей",
      "pl-pl": "Brak użytkowników do pokazania",
      "ua-ua": "Немає користувачів",
      "en-en": "There are no users to show",
      "hu-hu": "Nincsenek megjeleníthető felhasználók",
    },

    thereAreNoCategories: {
      "ru-ru": "Нет категорий",
      "pl-pl": "Brak kategorii do pokazania",
      "ua-ua": "Немає категорій",
      "en-en": "There are no categories to show",
      "hu-hu": "Nincsenek megjeleníthető kategóriák",
    },

    users: {
      "ru-ru": "Пользователи",
      "pl-pl": "Użytkownicy",
      "ua-ua": "Користувачі",
      "en-en": "Users",
      "hu-hu": "Felhasználók",
    },

    permissionCategories: {
      "ru-ru": "Категории прав доступа",
      "pl-pl": "Kategorie uprawnień",
      "ua-ua": "Категорії прав доступу",
      "en-en": "Permission categories",
      "hu-hu": "Engedélykategóriák",
    },

    category: {
      "ru-ru": "Категория",
      "pl-pl": "Kategoria",
      "ua-ua": "Категорія",
      "en-en": "Category",
      "hu-hu": "Kategória",
    },

    numberOfUsers: {
      "ru-ru": "Количество пользователей",
      "pl-pl": "Liczba użytkowników",
      "ua-ua": "Кількість користувачів",
      "en-en": "Number of users",
      "hu-hu": "Felhasználók száma",
    },

    editUser: {
      "ru-ru": "Редактирование пользователя",
      "pl-pl": "Edycja użytkownika",
      "ua-ua": "Редагування користувача",
      "en-en": "Edit user",
      "hu-hu": "Felhasználó szerkesztése",
    },

    inviteUser: {
      "ru-ru": "Пригласить пользователя",
      "pl-pl": "Zaproś użytkownika",
      "ua-ua": "Запросити користувача",
      "en-en": "Invite user",
      "hu-hu": "Felhasználó meghívása",
    },

    sendInvite: {
      "ru-ru": "Послать приглашение",
      "pl-pl": "Wyślij zaproszenie",
      "ua-ua": "Надіслати запрошення",
      "en-en": "Send invite",
      "hu-hu": "Meghívó küldése",
    },

    userInvited: {
      "ru-ru": "Приглашение отправлено",
      "pl-pl": "Zaproszenie zostało wysłane",
      "ua-ua": "Запрошення відправлене",
      "en-en": "The invite is sent",
      "hu-hu": "A meghívó elküldve",
    },

    addCategory: {
      "ru-ru": "Добавить категорию",
      "pl-pl": "Dodaj kategorię",
      "ua-ua": "Додати категорію",
      "en-en": "Add category",
      "hu-hu": "Kategória hozzáadása",
    },

    editCategory: {
      "ru-ru": "Редактировать категорию",
      "pl-pl": "Edytuj kategorię",
      "ua-ua": "Редагувати категорію",
      "en-en": "Edit category",
      "hu-hu": "Szerkessze a kategóriát",
    },

    acceptInvite: {
      "ru-ru": "Принять приглашение",
      "pl-pl": "Zaakceptuj zaproszenie",
      "ua-ua": "Прийняти запрошення",
      "en-en": "Accept invite",
      "hu-hu": "Meghívás elfogadása",
    },

    youHaveAcceptedTheInvite: {
      "ru-ru": "Вы приняли приглашение",
      "pl-pl": "Zaakceptowałeś zaproszenie",
      "ua-ua": "Ви прийняли запрошення",
      "en-en": "You have accepted the invite",
      "hu-hu": "Elfogadtad a meghívást",
    },

    permissions: {
      "ru-ru": "Разрешения",
      "pl-pl": "Uprawnienia",
      "ua-ua": "Дозволи",
      "en-en": "Permissions",
      "hu-hu": "Engedélyek",
    },

    permName: {
      deviceUsage: {
        "ru-ru": "Использование устройств",
        "pl-pl": "Użycie urządzenia",
        "ua-ua": "Використання пристроїв",
        "en-en": "Device usage",
        "hu-hu": "Eszközhasználat",
      },

      userManagement: {
        "ru-ru": "Управление пользователями и правами доступа",
        "pl-pl": "Zarządzanie użytkownikami i uprawnieniami",
        "ua-ua": "Керування користувачами і правами доступу",
        "en-en": "User and permission management",
        "hu-hu": "Felhasználó- és engedélykezelés",
      },

      financialStatistics: {
        "ru-ru": "Финансовая статистика",
        "pl-pl": "Statystyki finansowe",
        "ua-ua": "Фінансова статистика",
        "en-en": "Financial statistics",
        "hu-hu": "Pénzügyi statisztika",
      },

      settingUpPosts: {
        "ru-ru": "Настройка постов",
        "pl-pl": "Ustawienia stanowisk",
        "ua-ua": "Налаштування постів",
        "en-en": "Setting up posts",
        "hu-hu": "Autómosó beállítások",
      },

      telemetry: {
        "ru-ru": "Телеметрия",
        "pl-pl": "Telemetria",
        "ua-ua": "Телеметрія",
        "en-en": "Telemetry",
        "hu-hu": "Telemetria",
      },

      service: {
        "ru-ru": "Сервис",
        "pl-pl": "Serwis",
        "ua-ua": "Сервіс",
        "en-en": "Service",
        "hu-hu": "Karbantartás",
      },
    },

    categoryIsAdded: {
      "ru-ru": "Категория добалена",
      "pl-pl": "Kategoria została dodana",
      "ua-ua": "Категорію додано",
      "en-en": "Category is added",
      "hu-hu": "Kategória hozzáadva",
    },

    deletingUser: {
      "ru-ru": "Удаление пользователя",
      "pl-pl": "Usuwam użytkownika",
      "ua-ua": "Видалення користувача",
      "en-en": "Deleting user",
      "hu-hu": "Felhasználó törlése",
    },

    userDeleted: {
      "ru-ru": "Пользователь удален",
      "pl-pl": "Użytkownik został usunięty",
      "ua-ua": "Користувача видалено",
      "en-en": "User is deleted",
      "hu-hu": "Felhasználó törölve",
    },

    deletingPermissionCategory: {
      "ru-ru": "Удаление категрии прав доступа",
      "pl-pl": "Usuwanie kategorii uprawnień",
      "ua-ua": "Видалення категорії прав доступу",
      "en-en": "Deleting permission category",
      "hu-hu": "Engedélykategória törlése",
    },

    categoryDeleted: {
      "ru-ru": "Категория удалена",
      "pl-pl": "Kategoria została usunięta",
      "ua-ua": "Категорію видалено",
      "en-en": "Category is deleted",
      "hu-hu": "A kategória törölve",
    },

    sendingInvite: {
      "ru-ru": "Отправка приглашения",
      "pl-pl": "Wysyłam zaproszenie",
      "ua-ua": "Надсилання запрошення",
      "en-en": "Sending invite",
      "hu-hu": "Meghívó küldése",
    },

    doYouWantToSendInviteTo: {
      "ru-ru": "Отправить приглашение",
      "pl-pl": "Czy chcesz wysłać zaproszenie do",
      "ua-ua": "Надіслати запрошення до",
      "en-en": "Do you want to send invite to",
      "hu-hu": "Szeretne meghívót küldeni a címre",
    },

    doYouWantToDelete: {
      "ru-ru": "Удалить",
      "pl-pl": "Czy chcesz usunąć",
      "ua-ua": "Видалити",
      "en-en": "Do you want to delete the",
      "hu-hu": "Szeretné törölni a",
    },

    invite: {
      "ru-ru": "Пригласить",
      "pl-pl": "Zapraszać",
      "ua-ua": "Запросити",
      "en-en": "Invite",
      "hu-hu": "Meghívás",
    },

    impossibleToDelete: {
      "ru-ru": "Невозможно удалить",
      "pl-pl": "Nie można usunąć",
      "ua-ua": "Неможливо видалити",
      "en-en": "Impossible to delete",
      "hu-hu": "Lehetetlen törölni",
    },

    canNotDeleteCat: {
      "ru-ru": "Невозможно удалить категорию",
      "pl-pl": "Nie można usunąć kategorii",
      "ua-ua": "Неможливо видалити категорію",
      "en-en": "Can not delete category",
      "hu-hu": "A kategória nem törölhető",
    },

    canNotDeleteUser: {
      "ru-ru": "Невозможно удалить пользователя",
      "pl-pl": "Nie można usunąć użytkownika",
      "ua-ua": "Неможливо видалити користувача",
      "en-en": "Can not delete user",
      "hu-hu": "A felhasználót nem lehet törölni",
    },

    impossibleToInvite: {
      "ru-ru": "Невозможно пригласить",
      "pl-pl": "Nie można zaprosić",
      "ua-ua": "Неможливо запросити",
      "en-en": "Impossible to invite",
      "hu-hu": "Lehetetlen meghívni",
    },

    tooManyInvitesFor: {
      "ru-ru": "Слишком много приглашений для",
      "pl-pl": "Za dużo zaproszeń dla",
      "ua-ua": "Забагато запрошень для",
      "en-en": "Too many invites for",
      "hu-hu": "Túl sok meghívó ehhez",
    },

    lastInvite: {
      "ru-ru": "Последнее приглашение",
      "pl-pl": "Ostatnie zaproszenie",
      "ua-ua": "Останнє запрошення",
      "en-en": "Last invite",
      "hu-hu": "Utolsó meghívó",
    },

    tryAgainIn10Minutes: {
      "ru-ru": "Попробуйте снова через 10 минут",
      "pl-pl": "Spróbuj ponownie za 10 minut",
      "ua-ua": "Спробуйте знову через 10 хвилин",
      "en-en": "Try again in 10 minutes",
      "hu-hu": "Próbálja újra 10 perc múlva",
    },

    Owner: {
      "ru-ru": "Владелец",
      "pl-pl": "Właściciel",
      "ua-ua": "Власник",
      "en-en": "Owner",
      "hu-hu": "Tulajdonos",
    },
  },

  name: {
    "ru-ru": "Название",
    "pl-pl": "Nazwa",
    "ua-ua": "Назва",
    "en-en": "Name",
    "hu-hu": "Név",
  },

  yes: {
    "ru-ru": "Да",
    "pl-pl": "Tak",
    "ua-ua": "Так",
    "en-en": "Yes",
    "hu-hu": "Igen",
  },

  cancel: {
    "ru-ru": "Отменить",
    "pl-pl": "Anulować",
    "ua-ua": "Скасувати",
    "en-en": "Cancel",
    "hu-hu": "Megszünteti",
  },

  ok: {
    "ru-ru": "Ok",
    "pl-pl": "Ok",
    "ua-ua": "Ok",
    "en-en": "Ok",
    "hu-hu": "Ok",
  },

  changesSaved: {
    "ru-ru": "Изменения сохранены",
    "pl-pl": "Zmiany są zapisywane",
    "ua-ua": "Зміни збережено",
    "en-en": "Changes are saved",
    "hu-hu": "A változtatások mentésre kerülnek",
  },

  template: {
    "ru-ru": "__",
    "pl-pl": "__",
    "ua-ua": "__",
    "en-en": "__",
    "hu-hu": "__",
  },

  defaultLanguage: "en-en",
});
