import React, { useState, useEffect, useCallback, useRef } from "react";

const Select = ({ id, className, options, selectedOption, handleSelect, userSettings, icons = null }) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const selectRef = useRef();
  const selectDivRef = useRef();
  const selectOptionsDivRef = useRef();

  const selectToggle = useCallback(() => {
    setIsSelectOpen(!isSelectOpen);
  }, [isSelectOpen, setIsSelectOpen]);

  const handleOptionChange = useCallback(
    idx => () => {
      handleSelect(idx);
      selectToggle();
    },
    [selectToggle, handleSelect]
  );

  const handleClickOutside = useCallback(
    e => {
      if (
        selectDivRef.current &&
        selectOptionsDivRef.current &&
        !selectDivRef.current.contains(e.target) &&
        !selectOptionsDivRef.current.contains(e.target)
      )
        isSelectOpen && selectToggle();
    },
    [isSelectOpen, selectToggle]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectDivRef, selectOptionsDivRef, handleClickOutside]);

  return (
    <div
      className={`inline-block relative ${className ? className : ""} ${
        userSettings ? "w-full sm:w-3/4" : "w-full sm:w-auto"
      }`}>
      <select id={id} className="hidden" value={selectedOption} ref={selectRef} onChange={() => {}}>
        {options.map((option, idx) => (
          <option key={option + idx} value={idx}>
            {option}
          </option>
        ))}
      </select>
      <div
        className={`rounded ${
          userSettings
            ? `bg-background-color w-full text-sm lg:text-base xl:text-xl${isSelectOpen ? "" : " mb-px"}`
            : "bg-white w-full sm:w-40 md:w-40 mdlg:w-48 lg:w-56 text-sm mdlg:text-base border-b"
        } py-2 px-4 flex justify-between items-center cursor-pointer ${isSelectOpen ? "rounded-b-none" : ""} ${
          isSelectOpen && userSettings ? "border-b border-gray-100" : ""
        }`}
        ref={selectDivRef}
        onClick={selectToggle}>
        {icons && (
          <img src={icons[selectedOption]} style={{ left: "1rem" }} className="absolute flag-icon w-4" alt="" />
        )}
        <span className={`${icons ? "ml-6" : ""}`}>{options[selectedOption]}</span>
        <span
          className={`icon-arrow-black transform text-base xl:text-xl ${!isSelectOpen ? "rotate-180" : ""} `}></span>
      </div>
      <div
        ref={selectOptionsDivRef}
        className={`${
          userSettings
            ? "bg-background-color w-full text-sm lg:text-base xl:text-xl"
            : "bg-white w-full sm:w-40 md:w-40 mdlg:w-48 lg:w-56 text-sm mdlg:text-base"
        } px-4 pb-2 flex flex-col rounded-b absolute top-auto shadow-md z-10 ${!isSelectOpen ? "hidden" : ""}`}>
        {options.map((option, idx) => (
          <div
            className={`relative cursor-pointer py-1 hover:text-blue-800 ${
              idx === 0 ? "" : "border-t border-gray-100"
            } ${selectedOption === idx ? "text-blue-800" : ""}`}
            key={option + idx}
            onClick={handleOptionChange(idx)}>
            {icons && <img src={icons[idx]} className="absolute flag-icon w-4" alt="" />}
            <span className={`${icons ? "ml-6" : ""}`}>{option}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
