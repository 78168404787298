import React, { useEffect } from "react";
import { useMessage } from "../contexts/messages";

const Message = ({ children, id }) => {
  const { removeMessage } = useMessage();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeMessage(id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeMessage]);

  return <div className="text-center bg-primary text-white py-5 relative w-screen z-50">{children}</div>;
};

export default Message;
