import React, { useState, useEffect, Suspense, useRef, useCallback, useContext } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import useFetch from "../../hooks/useFetch";
import CurrentUserChecker from "../currentUserChecker";
import ObjectsChecker from "../objectsChecker";
import { ObjectProvider } from "../../contexts/object";
import { DevicesProvider } from "../../contexts/devices";
import { ObjectsContext } from "../../contexts/objects";
import { CurrentUserContext } from "../../contexts/currentUser";
import PermRoute from "../permRoute";

import Loader from "../Loader";
import Logo from "../../images/icw_logo.svg";

//import useScreenSize from "../../hooks/useScreenSize";

import getText from "../../utils/text";

import SideBar from "../SideBar";
import Profile from "../../pages/profile";
import ChangePassword from "../../pages/password";
import ObjectRedirect from "../ObjectRedirect";
import ConfigRedirect from "../ConfigRedirect";
const ObjectItem = React.lazy(() => import("../../pages/object"));
const DeviceItem = React.lazy(() => import("../../pages/device"));
const ServiceItem = React.lazy(() => import("../../pages/service"));
const ProgramItem = React.lazy(() => import("../../pages/program"));
const TExUseItem = React.lazy(() => import("../../pages/tex_use"));
const ConfigItem = React.lazy(() => import("../../pages/config"));
const TelemetryItem = React.lazy(() => import("../../pages/telemetry"));
const UserManagementItem = React.lazy(() => import("../../pages/userManagement"));

const text = getText();

const objectTimeZone = (zones, obj) => {
  const id = obj ? obj.timeZone : "Europe/Kyiv";
  let zone = zones.find(el => el.id === id); // Find as-is
  if (zone)
    return zone.name;
  if (id === "Europe/Kiev")
    zone = zones.find(el => el.id === "Europe/Kyiv"); // No 'Kiev', search for 'Kyiv'
  else if (id === "Europe/Kyiv")
    zone = zones.find(el => el.id === "Europe/Kiev"); // No 'Kyiv', search for 'Kiev'
  return zone ? zone.name : "";
}

const Layout = props => {
  const [{ response }, doFetch] = useFetch("/public/timezones");
  useEffect(() => {
    doFetch();
  }, [doFetch]);

  const objectId = props.location.pathname.split("/")[3];
  const { objects } = useContext(ObjectsContext);
  const object = objects.find(obj => obj.id === Number(objectId));

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const toggleSideBar = useCallback(() => setIsSideBarOpen(prevState => !prevState), [setIsSideBarOpen]);

  const mainWrapperRef = useRef(0);
  const mainAreaRef = useRef(0);
  const closeButtonRef = useRef(null);

  const [{ currentUser }] = useContext(CurrentUserContext);
  const currentUserLanguage =
    currentUser && currentUser.preferences && currentUser.preferences.ui.language
      ? currentUser.preferences.ui.language
      : text.defaultLanguage;

  // To remove debugging console log on this page, comment from here
  /*
  const [mainWrapperWidth, setMainWrapperWidth] = useState(mainWrapperRef.current.offsetWidth);
  const [mainAreaWidth, setMainAreaWidth] = useState(mainAreaRef.current.offsetWidth);

  const onResize = useCallback(() => {
    setMainWrapperWidth(mainWrapperRef.current.offsetWidth);
    setMainAreaWidth(mainAreaRef.current.offsetWidth);
  }, [setMainAreaWidth, setMainWrapperWidth]);

  const [, screenWidth] = useScreenSize(onResize);

  console.log("screen width: ", screenWidth);
  console.log("main wrapper width: ", mainWrapperWidth);
  console.log("main area width: ", mainAreaWidth);

  useEffect(() => {
    if (response) setMainAreaWidth(mainAreaRef.current.offsetWidth);
  }, [response, setMainAreaWidth]);
  */
  // To remove debugging console log on this page, comment till here

  return (
    <CurrentUserChecker>
      <ObjectsChecker>
        <div ref={mainWrapperRef} className="relative flex flex-1 min-h-screen max-w-full">
          <div className="md:hidden w-full bg-primary flex justify-center absolute top-0 left-0 z-30">
            <div
              ref={closeButtonRef}
              className={`${
                isSideBarOpen ? "bg-red-700" : "bg-black-500"
              } absolute left-0 cursor-pointer h-full px-6 flex flex-col justify-center items-center`}
              onClick={toggleSideBar}>
              <div className={`${isSideBarOpen ? "icon-cross" : "icon-menu"} font-black text-2xl`}></div>
              <span className="text-xs font-semibold text-white uppercase">{text.menu[currentUserLanguage]}</span>
            </div>
            <img
              src={Logo}
              className="h-12 lg:h-16 xl:h-20 mb-4 ml-4 lg:ml-5 xl:ml-6 mt-4 lg:mt-5 xl:mt-6"
              alt="logo"
            />
          </div>

          <SideBar isOpen={isSideBarOpen} closeButtonRef={closeButtonRef} toggleSideBar={toggleSideBar} />
          <Suspense fallback={<Loader type="main-window" />}>
            <div
              ref={mainAreaRef}
              className="bg-background-color flex-1 md:px-16 pt-12 pb-6 overflow-hidden flex flex-col justify-between relative">
              <div className="w-full">
                <Switch>
                  <Route path="/stat/profile" component={Profile} />
                  <Route path="/stat/password" component={ChangePassword} />
                  <Route path="/stat/object" component={ObjectRedirect} exact />
                  <Route
                    path={[
                      "/stat/object/:oid",
                      "/stat/object/:oid/usersAndPermissions",
                      "/stat/object/:oid/service",
                      "/stat/object/:oid/telemetry",
                      "/stat/object/:oid/payments",
                      "/stat/object/:oid/bays",
                      "/stat/object/:oid/bays/programs",
                      "/stat/object/:oid/bays/config",
                      "/stat/object/:oid/vaccs",
                      "/stat/object/:oid/vaccs/programs",
                      "/stat/object/:oid/texes",
                      "/stat/object/:oid/texes/use",
                      "/stat/object/:oid/bay/:did",
                      "/stat/object/:oid/bay/:did/programs",
                      "/stat/object/:oid/bay/:did/config",
                      "/stat/object/:oid/vacc/:did",
                      "/stat/object/:oid/vacc/:did/programs",
                      "/stat/object/:oid/tex/:did",
                      "/stat/object/:oid/tex/:did/use",
                    ]}
                    exact>
                    <ObjectProvider>
                      <DevicesProvider>
                        <PermRoute group="financialStatistics" path="/stat/object/:oid" exact><ObjectItem/></PermRoute>
                        <PermRoute group="userManagement" path="/stat/object/:oid/usersAndPermissions"><UserManagementItem/></PermRoute>
                        <PermRoute group="service" path="/stat/object/:oid/service"><ServiceItem/></PermRoute>
                        <PermRoute group="telemetry" path="/stat/object/:oid/telemetry"><TelemetryItem/></PermRoute>
                        <PermRoute group="financialStatistics" path="/stat/object/:oid/payments"><DeviceItem deviceType="all"/></PermRoute>
                        <PermRoute group="financialStatistics" path="/stat/object/:oid/bays" exact><DeviceItem deviceType="bays"/></PermRoute>
                        <PermRoute group="deviceUsage" path="/stat/object/:oid/bays/programs"><ProgramItem deviceType="bays"/></PermRoute>
                        <PermRoute group="settingUpPosts" path="/stat/object/:oid/bays/config"><ConfigRedirect/></PermRoute>
                        <PermRoute group="financialStatistics" path="/stat/object/:oid/vaccs" exact><DeviceItem deviceType="vaccs"/></PermRoute>
                        <PermRoute group="deviceUsage" path="/stat/object/:oid/vaccs/programs"><ProgramItem deviceType="vaccs"/></PermRoute>
                        <PermRoute group="financialStatistics" path="/stat/object/:oid/texes" exact><DeviceItem deviceType="texes"/></PermRoute>
                        <PermRoute group="deviceUsage" path="/stat/object/:oid/texes/use"><TExUseItem/></PermRoute>
                        <PermRoute group="financialStatistics" path="/stat/object/:oid/bay/:did" exact><DeviceItem deviceType="bay"/></PermRoute>
                        <PermRoute group="deviceUsage" path="/stat/object/:oid/bay/:did/programs"><ProgramItem deviceType="bay"/></PermRoute>
                        <PermRoute group="settingUpPosts" path="/stat/object/:oid/bay/:did/config"><ConfigItem/></PermRoute>
                        <PermRoute group="financialStatistics" path="/stat/object/:oid/vacc/:did" exact><DeviceItem deviceType="vacc"/></PermRoute>
                        <PermRoute group="deviceUsage" path="/stat/object/:oid/vacc/:did/programs"><ProgramItem deviceType="vacc"/></PermRoute>
                        <PermRoute group="financialStatistics" path="/stat/object/:oid/tex/:did" exact><DeviceItem deviceType="tex"/></PermRoute>
                        <PermRoute group="deviceUsage" path="/stat/object/:oid/tex/:did/use"><TExUseItem/></PermRoute>
                      </DevicesProvider>
                    </ObjectProvider>
                  </Route>

                  <Route component={() => <Redirect to="/stat/object" />} />
                </Switch>
              </div>
              <div className="px-4 sm:px-8 md:px-0 text-sm text-time ">
                {text.timeZone[currentUserLanguage]}{" "}
                {response && objectTimeZone(response, object)}
              </div>
            </div>
          </Suspense>
        </div>
      </ObjectsChecker>
    </CurrentUserChecker>
  );
};

export default withRouter(Layout);
