import React, { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

import { ObjectsContext } from "./objects";
import { toGroups } from "../utils/access";

export const ObjectContext = createContext();

export const ObjectProvider = ({ children }) => {
  const { oid: id } = useParams();

  const { objects } = useContext(ObjectsContext);

  const object = objects.find(obj => obj.id === Number(id));
  const groups = object ? toGroups(object.permissions) : [];

  return <ObjectContext.Provider value={{id, object, groups}}>{ object ? children : null }</ObjectContext.Provider>;
};
