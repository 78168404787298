import React, { createContext, useState, useCallback } from "react";

export const ObjectsContext = createContext();

export const ObjectsProvider = ({ children }) => {
  const [objects, setObjects] = useState([]);
  const addObjects = useCallback((objectItems = []) => {
    setObjects(objectItems);
  }, []);

  return <ObjectsContext.Provider value={{ addObjects, objects }}>{children}</ObjectsContext.Provider>;
};
