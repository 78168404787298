import React, { useState, useEffect, useContext, Fragment, useRef, useCallback } from "react";

import { CurrentUserContext } from "../../contexts/currentUser";
import useFetch from "../../hooks/useFetch";
import { useMessage } from "../../contexts/messages";

import Select from "../../components/select";

import iconRu from "../../images/ru-ru.png";
import iconUa from "../../images/ua-ua.png";
import iconEn from "../../images/en-en.png";
import iconPl from "../../images/pl-pl.png";
import iconHu from "../../images/hu-hu.png";

import getText from "../../utils/text";

const text = getText();

const languages = ["ru-ru", "ua-ua", "en-en", "pl-pl", "hu-hu"];
const languagesNames = ["Русский", "Українська", "English", "Polski", "Magyar"];
const icons = [iconRu, iconUa, iconEn, iconPl, iconHu];

const Profile = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [period, setPeriod] = useState(0);
  const [language, setLanguage] = useState(2);
  const [currentUserState, dispatch] = useContext(CurrentUserContext);
  const [{ fullResponse }, doFetch] = useFetch("/user/profile");
  const { addMessage } = useMessage();
  const nameRef = useRef(null);
  const currentUserLanguage =
    currentUserState.currentUser &&
    currentUserState.currentUser.preferences &&
    currentUserState.currentUser.preferences.ui.language
      ? currentUserState.currentUser.preferences.ui.language
      : languages[language];

  const handleSubmit = event => {
    event.preventDefault();
    if (name === "" || email === "") {
      addMessage(text.fillInTheFields[currentUserLanguage]);
      return;
    }
    doFetch({
      method: "PUT",
      data: {
        name,
        email,
        preferences: {
          ui: {
            language: languages[language],
            object_page: {
              period: period === 0 ? "month" : "collection",
            },
          },
        },
      },
    });
  };

  const selectPeriod = useCallback(idx => setPeriod(idx), [setPeriod]);
  const selectLanguage = useCallback(idx => setLanguage(idx), [setLanguage]);

  useEffect(() => {
    nameRef.current.focus();
  }, []);
  useEffect(() => {
    if (!currentUserState.currentUser) {
      return;
    }
    const currentUser = currentUserState.currentUser;
    setName(currentUser.name);
    setEmail(currentUser.email);
    if (currentUser.preferences) setPeriod(currentUser.preferences.ui.object_page.period === "collection" ? 1 : 0);
    else setPeriod(0);
    if (currentUser.preferences && currentUser.preferences.ui.language)
      setLanguage(languages.indexOf(currentUser.preferences.ui.language));
  }, [currentUserState.currentUser]);

  useEffect(() => {
    if (!fullResponse) {
      return;
    }

    dispatch({
      type: "UPDATE_USER",
      payload: {
        name,
        email,
        preferences: {
          ui: {
            language: languages[language],
            object_page: { period: period === 0 ? "month" : "collection" },
          },
        },
      },
    });
    addMessage(text.dataUpdated[currentUserLanguage]);
    // eslint-disable-next-line
  }, [fullResponse, dispatch, addMessage]);

  return (
    <Fragment>
      <div className="max-w-screen-md">
        <div className="absolute page-heading-position left-0 bg-white w-full px-4 sm:px-8 md:px-16 py-4 md:py-8 shadow">
          <div className="max-w-screen-md">
            <h1
              style={{ marginBottom: "calc(1rem + 3px)" }}
              className="whitespace-no-wrap mt-6 text-primary text-xl xs:text-2xl lg:text-3xl xl:text-4xl">
              {text.personalInformation[currentUserLanguage]}
            </h1>
          </div>
        </div>

        <div className="px-4 sm:px-8 md:px-0">
          <div className="w-full max-w-md lg:max-w-lg xl:max-w-xl bg-gradient rounded-t h-12 mt-40 sm:mt-48 md:mt-32 lg:mt-36 pr-4 pl-6 py-3 text-white flex justify-start items-center text-base xl:text-xl"></div>

          <form
            className="w-full max-w-md lg:max-w-lg xl:max-w-xl bg-white px-6 pb-6 pt-6 sm:pt-10 rounded-b"
            onSubmit={handleSubmit}>
            <div className="sm:flex sm:items-center mb-3 sm:mb-6">
              <div className="sm:w-1/4">
                <label className="block text-main pr-4 mb-2 sm:mb-0 text-sm lg:text-base xl:text-xl">
                  {text.fullName[currentUserLanguage]}
                </label>
              </div>
              <div className="sm:w-3/4">
                <input
                  className="bg-background-color rounded appearance-none w-full py-2 px-4 focus:outline-none text-sm lg:text-base xl:text-xl"
                  type="text"
                  minLength="3"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  ref={nameRef}
                />
              </div>
            </div>
            <div className="sm:flex sm:items-center mb-3 sm:mb-6">
              <div className="sm:w-1/4">
                <label className="block text-main pr-4 mb-2 sm:mb-0 text-sm lg:text-base xl:text-xl">Email</label>
              </div>
              <div className="sm:w-3/4">
                <input
                  className="bg-background-color rounded appearance-none w-full py-2 px-4 focus:outline-none text-sm lg:text-base xl:text-xl"
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:flex sm:items-center mb-5 sm:mb-6">
              <div className="sm:w-1/4">
                <label className="block text-main pr-4 mb-2 sm:mb-0 text-sm lg:text-base xl:text-xl">
                  {text.language[currentUserLanguage]}
                </label>
              </div>
              <Select
                options={languagesNames}
                selectedOption={language}
                handleSelect={selectLanguage}
                icons={icons}
                userSettings
              />
            </div>
            <div className="sm:flex sm:items-center mb-8 sm:mb-6">
              <div className="sm:w-1/4 sm:relative h-5">
                <label
                  style={{ top: "50%", left: "0", transform: "translateY(-50%)" }}
                  className="whitespace-no-wrap sm:whitespace-normal sm:absolute block text-main sm:pr-4 mb-2 sm:mb-0 text-sm lg:text-base xl:text-xl ">
                  {text.periodOnTheObjectPage[currentUserLanguage]}
                </label>
              </div>
              <Select
                options={[text.currentMonth[currentUserLanguage], text.afterEncashment[currentUserLanguage]]}
                selectedOption={period}
                handleSelect={selectPeriod}
                userSettings
              />
            </div>
            <div className="flex items-center justify-end">
              <button
                className="btn bg-main rounded w-full sm:w-auto py-2 px-16 text-sm lg:text-base xl:text-xl text-white focus:outline-none hover:bg-main-hover disabled:opacity-75 disabled:cursor-not-allowed"
                type="submit">
                {text.save[currentUserLanguage]}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
