import { useCallback } from "react";

import { useXLate } from "./useXLate";
import { useMessage } from "../contexts/messages";
import { errorCodeToMessage } from "../utils/handleError";

export const useErrorHandler = () => {
  const xlate = useXLate();
  const { addMessage } = useMessage();
  return useCallback(
    (code) => {
      addMessage(xlate(errorCodeToMessage(code)));
    },
    [xlate, addMessage]
  );
}
