import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";

import Logo from "../../images/icw_logo.svg";

import useFetch from "../../hooks/useFetch";
import useSessionStorage from "../../hooks/useSessionStorage";
import { useMessage } from "../../contexts/messages";

import getText from "../../utils/text";

const text = getText();

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isSuccessfullSubmit, setIsSuccessfullSubmit] = useState(false);
  const [{ response, error, isLoading }, doFetch] = useFetch("/token/issue/user");
  const [, setToken] = useSessionStorage("token");
  const { addMessage } = useMessage();
  const usernameRef = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();
    doFetch({
      method: "GET",
      auth: {
        username,
        password,
      },
    });
  };

  useEffect(() => {
    usernameRef.current.focus();
  }, []);

  useEffect(() => {
    if (!response) {
      return;
    }

    setToken(response);

    // refreshToken(response);

    setIsSuccessfullSubmit(true);
  }, [response, setToken]);

  useEffect(() => {
    if (!error) {
      return;
    }
    if (error === 401) {
      addMessage(text.notCorrectLoginOrPassword["en-en"]);
    }
  }, [error, addMessage]);

  if (isSuccessfullSubmit) {
    return <Redirect to="/" />;
  }

  return (
    <div className="flex flex-col h-screen my-auto items-center justify-center align-middle bg-background-color">
      <img src={Logo} className="mb-12 h-12 lg:h-16 xl:h-20" alt="logo" />
      <h1 className="text-lg lg:text-xl xl:text-2xl mb-6 font-bold text-primary">{text.signIn["en-en"]}</h1>
      <form className="w-full max-w-xs xl:max-w-sm" onSubmit={handleSubmit}>
        <div className="flex items-center mb-6 rounded bg-white">
          <div className="w-1/4">
            <label
              className="block px-1 py-3 xl:py-4 text-center text-label text-sm lg:text-base xl:text-lg"
              htmlFor="username">
              {text.login["en-en"]}
            </label>
          </div>
          <div className="w-3/4">
            <input
              type="text"
              className="appearance-none w-full py-2 pr-2 focus:outline-none bg-transparent text-sm lg:text-base xl:text-lg"
              value={username}
              onChange={e => setUsername(e.target.value)}
              ref={usernameRef}
            />
          </div>
        </div>
        <div className="flex items-center mb-6 rounded bg-white">
          <div className="w-1/4">
            <label
              className="block px-1 py-3 xl:py-4 text-center text-label text-sm lg:text-base xl:text-lg"
              htmlFor="password">
              {text.password["en-en"]}
            </label>
          </div>
          <div className="w-3/4">
            <input
              type="password"
              className="appearance-none w-full py-2 pr-2 focus:outline-none bg-transparent text-sm lg:text-base xl:text-lg"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center mb-6">
          <button
            className="btn bg-main rounded w-full py-3 xl:py-4 text-white focus:outline-none hover:bg-main-hover disabled:opacity-75 text-sm lg:text-base xl:text-lg"
            type="submit"
            disabled={isLoading}>
            {text.signIn["en-en"]}
          </button>
        </div>
        <div className="text-center">
          <a className="text-main text-sm lg:text-base xl:text-lg" href="/restore">
            {text.forgotPassword["en-en"]}
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
