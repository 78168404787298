import React, { useState, useEffect, useRef } from "react";
import useFetch from "../../hooks/useFetch";
import { useMessage } from "../../contexts/messages";
import Logo from "../../images/icw_logo.svg";

import getText from "../../utils/text";

const text = getText();

const Restore = () => {
  const [{ fullResponse, error, isLoading }, doFetch] = useFetch("/user/restorePasswordRequest");
  const [email, setEmail] = useState("");
  const { addMessage } = useMessage();
  const emailRef = useRef(null);

  const handleSubmit = event => {
    event.preventDefault();

    if (email === "") {
      addMessage(text.fillInTheEmail["en-en"]);
      return;
    }
    doFetch({
      method: "POST",
      data: {
        email,
      },
    });
  };

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    if (!fullResponse) {
      return;
    }

    if (fullResponse.status === 200) {
      addMessage(text.youHaveBeenSentALinkToResetYourPasswordByEmail["en-en"]);
    }
  }, [fullResponse, addMessage]);

  useEffect(() => {
    if (!error) {
      return;
    }
    if (error === 404) {
      addMessage(text.noSuchEmail["en-en"]);
    }
    if (error === 429) {
      addMessage(text.checkEmailOrTryALittleLater["en-en"]);
    }
  }, [error, addMessage]);

  return (
    <div className="flex flex-col h-screen my-auto items-center justify-center align-middle bg-background-color">
      <img src={Logo} className="mb-12 h-12 lg:h-16 xl:h-20" alt="logo" />
      <div className="w-full max-w-xs text-center xl:max-w-sm">
        <h1 className="text-lg lg:text-xl xl:text-2xl mb-6 font-bold text-primary">{text.passwordRecovery["en-en"]}</h1>
        <p className="mt-4 mb-6 text-sm lg:text-base xl:text-lg">
          {text.enterYourEmailALinkToChangeYourPasswordWillBeSentToIt["en-en"]}
        </p>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-6 rounded bg-white">
            <div className="w-1/4">
              <label
                className="block px-1 py-3 xl:py-4 text-center text-label text-sm lg:text-base xl:text-lg"
                htmlFor="email">
                Email
              </label>
            </div>
            <div className="w-3/4">
              <input
                type="email"
                className="appearance-none w-full py-2 pr-2 focus:outline-none bg-transparent text-sm lg:text-base xl:text-lg"
                value={email}
                onChange={e => setEmail(e.target.value)}
                ref={emailRef}
              />
            </div>
          </div>
          <div className="flex items-center mb-6">
            <button
              className="relative btn bg-main rounded w-full py-3 xl:py-4 text-white focus:outline-none hover:bg-main-hover disabled:opacity-75 text-sm lg:text-base xl:text-lg"
              type="submit">
              {isLoading && (
                <div className="absolute top-33 left-35px loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-4 w-4 mr-4"></div>
              )}
              {text.restorePassword["en-en"]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Restore;
