import React, { useState, useEffect, Fragment, useRef, useContext } from "react";

import useFetch from "../../hooks/useFetch";

import { useMessage } from "../../contexts/messages";
import { CurrentUserContext } from "../../contexts/currentUser";

import getText from "../../utils/text";

const text = getText();

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const passwordRef = useRef(null);

  const { addMessage } = useMessage();

  const [{ fullResponse, error }, doFetch] = useFetch("/user/changePassword");

  const [{ currentUser }] = useContext(CurrentUserContext);
  const currentUserLanguage =
    currentUser && currentUser.preferences && currentUser.preferences.ui.language
      ? currentUser.preferences.ui.language
      : text.defaultLanguage;

  const handleSubmit = event => {
    event.preventDefault();

    if (newPassword === "" || confirmPassword === "" || oldPassword === "") {
      addMessage(text.passwordsAreEmpty[currentUserLanguage]);
      return;
    }
    if (newPassword !== confirmPassword) {
      addMessage(text.passwordsDontMatch[currentUserLanguage]);
      return;
    }

    doFetch({
      method: "PUT",
      data: {
        old: oldPassword,
        new: newPassword,
      },
    });
  };

  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  useEffect(() => {
    if (!fullResponse) {
      return;
    }

    if (fullResponse.status === 200) {
      addMessage(text.passwordChanged[currentUserLanguage]);
    }
  }, [fullResponse, addMessage, currentUserLanguage]);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (error === 404) {
      addMessage(text.invalidOldPassword[currentUserLanguage]);
    }
  }, [error, addMessage, currentUserLanguage]);

  return (
    <Fragment>
      <div className="max-w-screen-md">
        <div className="absolute page-heading-position left-0 bg-white w-full px-4 sm:px-8 md:px-16 py-4 md:py-8 shadow">
          <div className="max-w-screen-md">
            <h1
              style={{ marginBottom: "calc(1rem + 3px)" }}
              className="whitespace-no-wrap mt-6 text-primary text-xl xs:text-2xl lg:text-3xl xl:text-4xl">
              {text.passwordChange[currentUserLanguage]}
            </h1>
          </div>
        </div>

        <div className="px-4 sm:px-8 md:px-0">
          <div className="w-full max-w-md lg:max-w-lg xl:max-w-xl bg-gradient rounded-t h-12 mt-40 sm:mt-48 md:mt-32 lg:mt-36 pr-4 pl-6 py-3 text-white flex justify-start items-center text-base xl:text-xl"></div>

          <form
            className="w-full max-w-md lg:max-w-lg xl:max-w-xl bg-white px-6 pb-6 pt-6 sm:pt-10 rounded-b"
            onSubmit={handleSubmit}>
            <div className="sm:flex sm:items-center mb-3 sm:mb-6">
              <div className="sm:w-1/3">
                <label className="block text-main pr-4 mb-2 sm:mb-0 text-sm lg:text-base xl:text-xl">
                  {text.oldPassword[currentUserLanguage]}
                </label>
              </div>
              <div className="sm:w-2/3">
                <input
                  className="bg-background-color rounded appearance-none w-full py-2 px-4 focus:outline-none text-sm lg:text-base xl:text-xl"
                  type="password"
                  value={oldPassword}
                  onChange={e => setOldPassword(e.target.value)}
                  ref={passwordRef}
                />
              </div>
            </div>
            <div className="sm:flex sm:items-center mb-6 sm:mb-6">
              <div className="sm:w-1/3">
                <label className="block text-main pr-4 mb-2 sm:mb-0 text-sm lg:text-base xl:text-xl">
                  {text.newPassword[currentUserLanguage]}
                </label>
              </div>
              <div className="sm:w-2/3">
                <input
                  className="bg-background-color rounded appearance-none w-full py-2 px-4 focus:outline-none text-sm lg:text-base xl:text-xl"
                  type="password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="sm:flex sm:items-center mb-8 sm:mb-6">
              <div className="sm:w-1/3 relative h-5">
                <label
                  style={{ top: "50%", left: "0", transform: "translateY(-50%)" }}
                  className="whitespace-no-wrap sm:whitespace-normal sm:absolute block text-main sm:pr-4 mb-2 sm:mb-0 text-sm lg:text-base xl:text-xl ">
                  {text.passwordConfirmation[currentUserLanguage]}
                </label>
              </div>
              <div className="sm:w-2/3">
                <input
                  className="bg-background-color rounded appearance-none w-full py-2 px-4 focus:outline-none text-sm lg:text-base xl:text-xl"
                  type="password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="flex items-center justify-end">
              <button
                className="btn bg-main rounded w-full sm:w-auto py-2 px-16 text-sm lg:text-base xl:text-xl text-white focus:outline-none hover:bg-main-hover disabled:opacity-75 disabled:cursor-not-allowed"
                type="submit">
                {text.save[currentUserLanguage]}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default ChangePassword;
