import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Loader from "./components/Loader";
import Layout from "./components/layout";
import Login from "./pages/login";
import Restore from "./pages/restore";
import Restoration from "./pages/restoration";
import AcceptInvite from "./pages/acceptInvite";

export default () => {
  return (
    <Switch>
      <React.Suspense fallback={<Loader />}>
        <Route exact path="/" render={() => <Redirect to="/stat/object" />} />
        <Route path="/stat" component={Layout} />
        <Route path="/login" component={Login} />
        <Route path="/restore" component={Restore} exact />
        <Route path="/passwordRestoration" component={Restoration} />
        <Route path="/acceptInvite" component={AcceptInvite} />
      </React.Suspense>
    </Switch>
  );
};
