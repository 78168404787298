import React, { useState, useEffect, useRef } from "react";
import queryString from "query-string";
import { Redirect } from "react-router-dom";

import { useMessage } from "../../contexts/messages";
import Logo from "../../images/icw_logo.svg";
import useFetch from "../../hooks/useFetch";

import getText from "../../utils/text";

const text = getText();

const AcceptInvite = ({ location }) => {
  const { key } = queryString.parse(location.search);
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSuccessfullSubmit, setIsSuccessfullSubmit] = useState(false);
  const { addMessage } = useMessage();
  const passwordRef = useRef(null);

  const nameRef = useRef(null);

  const [{ fullResponse, error }, doFetch] = useFetch("/user/acceptInvite");

  const handleSubmit = event => {
    event.preventDefault();
    if (password === "" && confirmPassword === "") {
      addMessage(text.fillInTheFields["en-en"]);
      return;
    }
    if (password !== confirmPassword) {
      addMessage(text.passwordsDontMatch["en-en"]);
      return;
    }

    doFetch({
      method: "PUT",
      data: {
        key,
        password,
        fullName,
      },
    });
  };

  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  useEffect(() => {
    if (!fullResponse) {
      return;
    }
    if (fullResponse.status === 200) {
      addMessage(text.userMgmt.youHaveAcceptedTheInvite["en-en"]);
      setTimeout(() => {
        setIsSuccessfullSubmit(true);
      }, 2000);
    }
  }, [fullResponse, addMessage]);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (error === 404) {
      addMessage(text.invalidKey["en-en"]);
    }

    if (error === 401) {
      addMessage(text.keyExpiredPleaseRetryRequest["en-en"]);
      setTimeout(() => {
        setIsSuccessfullSubmit(true);
      }, 2000);
    }
  }, [error, addMessage]);

  if (isSuccessfullSubmit) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="flex flex-col h-screen my-auto items-center justify-center align-middle bg-background-color">
      <img src={Logo} className="h-12 mb-12" alt="logo" />
      <div className="w-full max-w-xs text-center">
        <h1 className="text-lg lg:text-xl xl:text-2xl mb-6 font-bold text-primary">{text.userMgmt.acceptInvite["en-en"]}</h1>
        <form onSubmit={handleSubmit}>
          <div className="flex items-center mb-6 rounded bg-white">
            <input
              type="text"
              className="appearance-none w-full py-3 px-4 focus:outline-none bg-transparent text-sm lg:text-base xl:text-lg"
              placeholder={text.fullName["en-en"]}
              value={fullName}
              onChange={e => setFullName(e.target.value)}
              ref={nameRef}
            />
          </div>
          <div className="flex items-center mb-6 rounded bg-white">
            <input
              type="password"
              className="appearance-none w-full py-3 px-4 focus:outline-none bg-transparent text-sm lg:text-base xl:text-lg"
              placeholder={text.newPassword["en-en"]}
              value={password}
              onChange={e => setPassword(e.target.value)}
              ref={passwordRef}
            />
          </div>
          <div className="flex items-center mb-6 rounded bg-white">
            <input
              type="password"
              className="appearance-none w-full py-3 px-4 focus:outline-none bg-transparent text-sm lg:text-base xl:text-lg"
              placeholder={text.passwordConfirmation["en-en"]}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="flex items-center mb-6">
            <button
              className="btn bg-main rounded w-full py-3 text-white focus:outline-none hover:bg-main-hover disabled:opacity-75 text-sm lg:text-base xl:text-lg"
              type="submit">
              {text.userMgmt.acceptInvite["en-en"]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AcceptInvite;
