import { useContext, useCallback } from "react";

import { CurrentUserContext } from "../contexts/currentUser";

import getText from "../utils/text";

const texts = getText();

const xlate = (text, ss, texts, lang) => {
  const sections = Array.isArray(ss) ? ss : ss ? [ss] : [];
  const t = sections.reduce((o, p) => o && o[p] ? o[p] : null, texts);

  if (t && t[text])
    return t[text][lang];

  return text;
}

export const useCurrentUserLang = () => {
  const [{ currentUser }] = useContext(CurrentUserContext);
  return currentUser && currentUser.preferences && currentUser.preferences.ui.language
      ? currentUser.preferences.ui.language
      : texts.defaultLanguage;
}

export const useXLate = () => {
  const lang = useCurrentUserLang()

  return useCallback((text, sections = null) => xlate(text, sections, texts, lang), [lang]);
}
