import axios from "axios";

import jwtDecode from "jwt-decode";

const refreshToken = token => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const requestOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  axios(`${baseUrl}/token/renew`, requestOptions)
    .then(response => {
      const newToken = response.data;
      sessionStorage.setItem("token", newToken);
      const { exp } = jwtDecode(newToken);
      const time = (exp * 1000 - Date.now()) / 2;
      window.tokenExpire = setTimeout(() => refreshToken(newToken), time);
    })
    .catch(error => {
      console.log(error);
      clearTimeout(window.tokenExpire);
    });

  clearTimeout(window.tokenExpire);
};

export default refreshToken;
