export const accessGroups = {
  financialStatistics: ["finance", "payments", "collections", "manual_collections", "card_refills"],
  deviceUsage: ["use", "token_management", "errors"],
  settingUpPosts: ["config", "telemetry"],
  telemetry: ["telemetry"],
  service: ["timers"],
  userManagement: ["user_management"]
};

export const toGroups = permissions => {
  return Object.keys(accessGroups).filter(g => {
    return accessGroups[g].reduce((a, v) => a && permissions.includes(v), true);
  });
};
