import React, { useContext, useState, useEffect, Fragment, useRef } from "react";
import { Redirect, NavLink, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import useSessionStorage from "../hooks/useSessionStorage";
import Logo from "../images/icw_logo.svg";

import { CurrentUserContext } from "../contexts/currentUser";
import { ObjectsContext } from "../contexts/objects";

import getText from "../utils/text";

import { toGroups } from "../utils/access";

const text = getText();

const SideBar = ({ isOpen, closeButtonRef, toggleSideBar }) => {
  const [{ currentUser }] = useContext(CurrentUserContext);
  const { objects, addObjects } = useContext(ObjectsContext);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [, setToken] = useSessionStorage("token");
  const [successfullLogout, setIsSuccessfullLogout] = useState(false);
  const { pathname } = useLocation();

  const wrapperRef = useRef(null);

  const queryArray = pathname.split("/");
  const bayType = queryArray[queryArray.indexOf("object") + 2];
  const objectId = queryArray[queryArray.indexOf("object") + 1];

  const currentUserLanguage =
    currentUser && currentUser.preferences && currentUser.preferences.ui.language
      ? currentUser.preferences.ui.language
      : text.defaultLanguage;

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = event => {
        if (
          wrapperRef.current &&
          closeButtonRef &&
          !wrapperRef.current.contains(event.target) &&
          !closeButtonRef.current.contains(event.target)
        )
          toggleSideBar();
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [isOpen, wrapperRef, closeButtonRef, toggleSideBar]);

  const logout = () => {
    addObjects();
    setToken("");
    setIsSuccessfullLogout(true);
    clearTimeout(window.tokenExpire);
  };

  if (successfullLogout) {
    return <Redirect to="/login" />;
  }

  return (
    <aside
      ref={wrapperRef}
      className={`bg-primary fixed z-20 h-full md:h-auto top-0 left-0 md:static w-56 lg:w-64 xl:w-1/6 ${
        isOpen ? "block" : "hidden"
      } md:block`}>
      <Link to="/stat/object">
        <img src={Logo} className="h-12 lg:h-16 xl:h-20 mb-4 mx-auto mt-4 lg:mt-5 xl:mt-6" alt="logo" />
      </Link>
      <ul className="text-white text-xs lg:text-sm xl:text-base">
        <li className={`border-t border-table-label ${isUserOpen ? "open" : ""}`}>
          <span
            onClick={() => setIsUserOpen(!isUserOpen)}
            className={`block pt-4 pb-4 px-4 opacity-75 hover:opacity-100 cursor-pointer flex justify-between items-center 
            ${isUserOpen ? "opacity-100" : ""}`}>
            <span>
              <span className="icon-man-1 mr-2"></span>
              {currentUser && currentUser.name}
            </span>
            <FontAwesomeIcon icon={isUserOpen ? faChevronDown : faChevronRight} />
          </span>

          <ul className={isUserOpen ? "block" : "hidden"}>
            <li onClick={() => isOpen && toggleSideBar()} className="border-t border-main-border">
              <NavLink to="/stat/profile" className="block pt-4 pb-4 pl-4 opacity-75 hover:opacity-100 menu-link">
                {text.personalInformation[currentUserLanguage]}
              </NavLink>
            </li>
            <li onClick={() => isOpen && toggleSideBar()} className="border-t border-main-border">
              <NavLink to="/stat/password" className="block pt-4 pb-4 pl-4 opacity-75 hover:opacity-100 menu-link">
                {text.passwordChange[currentUserLanguage]}
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="border-t border-main-border open">
          <span className="border-b border-main-border block pt-4 pb-4 px-4 opacity-100 flex justify-between items-center ">
            <span>
              <span className="icon-car mr-2"></span>
              {text.yourObjects[currentUserLanguage]}
            </span>
          </span>

          <ul
            onClick={() => isOpen && toggleSideBar()}
            className={`${
              isUserOpen ? "side-menu-list-scrolable__user-open" : "side-menu-list-scrolable__user-closed"
            }`}>
            {objects &&
              objects.map((object, index) => {
                const objectLink = `/stat/object/${object.id}`;
                const match = object.id === Number(objectId);
                const matchPrograms = pathname.endsWith("/programs");
                const matchUse = pathname.endsWith("/use");
                const matchConfig = pathname.endsWith("/config");
                const matchBayPrograms = matchPrograms && bayType.startsWith("bay");
                const matchVaccPrograms = matchPrograms && bayType.startsWith("vacc");
                const matchTExUse = matchUse && bayType.startsWith("tex");
                const matchService = pathname.startsWith(`${objectLink}/service`);
                const matchTelemetry = pathname.startsWith(`${objectLink}/telemetry`);
                const matchUsers = pathname.startsWith(`${objectLink}/usersAndPermissions`);
                const matchFinancial =  !matchPrograms
                                     && !matchTExUse
                                     && !matchService
                                     && !matchConfig
                                     && !matchTelemetry
                                     && !matchUsers;

                const MenuItem = ({group, name, link, active}) => {
                  const groups = object ? toGroups(object.permissions) : [];
                  if (!groups.includes(group))
                    return (null);
                  return (
                    <li className="border-t border-white-150">
                      <Link
                        to={link}
                        className={`block py-2 pr-4 pl-8 bg-white-100 hover:text-white ${
                          active ? "text-white" : "text-gray-400"
                        }`}>
                        {name}
                      </Link>
                    </li>
                  );
                };

                return (
                  <Fragment key={object.id}>
                    <li className={`${index === 0 ? " " : "border-t "}border-main-border`}>
                      <NavLink
                        to={objectLink}
                        className="flex justify-between py-4 px-4 opacity-75 hover:opacity-100 menu-link te">
                        <span>
                          {text.cityShorten[currentUserLanguage]} {object.city}, {object.address}
                        </span>
                        <div className={`self-center px-2 py-2 -mr-2 ${match ? "" : "hidden"}`}>
                          <FontAwesomeIcon icon={match ? faChevronDown : faChevronRight} />
                        </div>
                      </NavLink>
                    </li>
                    <ul className={match ? "block" : "hidden"}>
                      <MenuItem group="financialStatistics" name={text.financialStatistics[currentUserLanguage]} link={objectLink} active={matchFinancial}/>
                      <MenuItem group="deviceUsage" name={text.usingPosts[currentUserLanguage]} link={`${objectLink}/bays/programs`} active={matchBayPrograms}/>
                      <MenuItem group="deviceUsage" name={text.usingVacuumCleaners[currentUserLanguage]} link={`${objectLink}/vaccs/programs`} active={matchVaccPrograms}/>
                      <MenuItem group="deviceUsage" name={text.usingTExes[currentUserLanguage]} link={`${objectLink}/texes/use`} active={matchTExUse}/>
                      <MenuItem group="settingUpPosts" name={text.settingUpPosts[currentUserLanguage]} link={`${objectLink}/bays/config`} active={matchConfig}/>
                      <MenuItem group="telemetry" name={text.telemetry[currentUserLanguage]} link={`${objectLink}/telemetry`} active={matchTelemetry}/>
                      <MenuItem group="service" name={text.service[currentUserLanguage]} link={`${objectLink}/service`} active={matchService}/>
                      <MenuItem group="userManagement" name={text.userMgmt.usersAndPermissions[currentUserLanguage]} link={`${objectLink}/usersAndPermissions`} active={matchUsers}/>
                    </ul>
                  </Fragment>
                );
              })}
          </ul>
        </li>
        <li onClick={() => isOpen && toggleSideBar()} className="border-t border-b border-main-border">
          <span
            onClick={logout}
            className="block pt-4 pb-4 px-4 opacity-75 hover:opacity-100 cursor-pointer flex items-center ">
            <span className="icon-exit mr-2"></span>
            {text.signOut[currentUserLanguage]}
          </span>
        </li>
      </ul>
    </aside>
  );
};

export default SideBar;
